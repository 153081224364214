import type { AvailableOfferEnquiryResponseData } from '@svelte/lib/entity/available-offer.entity';
import type { Enquiry } from '@svelte/lib/interface/enquiry';

export function isNumberAndNotNaN(value: unknown): value is number {
  return typeof value === 'number' && !isNaN(value);
}

export const availableOfferEntityToEntityMapper = (
  enquiry: AvailableOfferEnquiryResponseData
): Enquiry => {
  const resultEnquiry: Enquiry = {
    serviceType: enquiry.serviceType.value,
    game: enquiry.game.value,
    initialLeagueDivision: enquiry.initialLeagueDivision?.value,
    desiredLeagueDivision: enquiry.desiredLeagueDivision?.value,
    numberOfWins: enquiry.numberOfWins,
    numberOfPlacements: enquiry.numberOfPlacements,
    initialLevel: enquiry.initialLevel,
    desiredLevel: enquiry.desiredLevel,
    numberOfGames: enquiry.numberOfGames,
    typeOfCoach: enquiry.queueTypeEnum?.value,
    numberOfHours: enquiry.numberOfHours,
    initialMasteryLevel: enquiry.initialMasteryLevel?.value,
    desiredMasteryLevel: enquiry.desiredMasteryLevel?.value,
    queueTypeEnum: enquiry.queueTypeEnum?.value,
    numberOfKills: enquiry.numberOfKills?.toString?.(),
    platform: enquiry.platform?.value,
    badge: enquiry.badge?.value,
    challenge: enquiry.challenge?.value,
    desiredLp: enquiry.desiredLp,
    currentRr: enquiry.currentRr,
    desiredRr: enquiry.currentRr
  };

  if (
    isNumberAndNotNaN(enquiry.currentLp) &&
    enquiry.currentLp !== undefined &&
    enquiry.currentLp !== null
  ) {
    resultEnquiry.currentLp = enquiry.currentLp;
  }

  return resultEnquiry;
};
