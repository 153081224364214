import type { Game } from '@svelte/lib/enum/drivers/base';
import { getLeagueDivisionLabel } from '@svelte/lib/helpers/drivers/leagueDivision';
import { getQueueTypeLabel } from '@svelte/lib/helpers/drivers/queueType';

export function rankQueueTypeInitialLabel(
  initialLeagueDivision: string | undefined,
  queueType: string | undefined,
  game: Game
): string {
  const league = initialLeagueDivision ? getLeagueDivisionLabel(game, initialLeagueDivision) : '';

  const queueTypeLabel = undefined === queueType ? '' : getQueueTypeLabel(game, queueType);

  if (league === '' && queueTypeLabel === '') {
    return 'Any ranked';
  }

  return [league, queueTypeLabel].filter((t) => t).join(' - ');
}
