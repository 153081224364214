export enum Currency {
  EUR = 'EUR',
  USD = 'USD'
}

export type CurrencySymbol = '$' | '€';

export enum ActiveBoostersService {
  boosting = 'boosting',
  coaching = 'coaching'
}

export enum PokeType {
  BOOST = 'boost',
  COACHING = 'coaching',
  OFFER = 'offer'
}
