import type { SelectOption } from '@svelte/lib/interface/types';
import type { AttributesObject } from '@svelte/lib/mapper/boosting/preparedData';

export function parseMasteryLevelValue(masteryLevelValue: string): [string, string | undefined] {
  const [level, token] = masteryLevelValue.split('-token-');

  return [level, token];
}

export function parseMasteryLevelName(masteryLevelName: string): [string, string | undefined] {
  const [level, token] = masteryLevelName.split('_TOKEN_');
  const levelLabel = level.replace(/_/g, ' ');

  if (token === undefined) {
    return [levelLabel, token];
  }

  const tokenLabel = `${token} Token`;
  return [levelLabel, tokenLabel];
}

export function getDriverMasteryLevelValue(level: string, token: string | undefined): string {
  return token ? `${level}-token-${token}` : level;
}

export function getTokenOptions(
  masteryLevel: AttributesObject,
  masteryLevelValue: string
): SelectOption[] {
  if (
    'options' in masteryLevel &&
    Array.isArray(masteryLevel.options) &&
    masteryLevel?.options?.length > 0
  ) {
    const levelOptionByValue = masteryLevel.options.find(
      (league) => league.value === masteryLevelValue
    );

    const isTokenOptions =
      levelOptionByValue &&
      'options' in levelOptionByValue &&
      Array.isArray(levelOptionByValue.options);

    return isTokenOptions ? (levelOptionByValue.options as SelectOption[]) : [];
  }

  return [];
}

export function getMasteryLevelLabel(masteryLevelValue: string): string {
  switch (masteryLevelValue) {
    case 'lol_mlv_0':
      return 'Level 0';

    case 'lol_mlv_1':
      return 'Level 1';

    case 'lol_mlv_2':
      return 'Level 2';

    case 'lol_mlv_3':
      return 'Level 3';

    case 'lol_mlv_4':
      return 'Level 4';

    case 'lol_mlv_5':
      return 'Level 5';

    case 'lol_mlv_5-token-0':
      return 'Level 5 token 0';

    case 'lol_mlv_5-token-1':
      return 'Level 5 token 1';

    case 'lol_mlv_6':
      return 'Level 6';

    case 'lol_mlv_6-token-0':
      return 'Level 6 token 0';

    case 'lol_mlv_6-token-1':
      return 'Level 6 token 1';

    case 'lol_mlv_6-token-2':
      return 'Level 6 token 2';

    case 'lol_mlv_7':
      return 'Level 7';

    default:
      console.log(`Unknown mastery level: ${masteryLevelValue}`);
      return 'Unknown level';
  }
}
