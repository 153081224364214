export enum CommonFilterInputType {
  checkbox = 'checkbox',
  button = 'button',
  buttonRadio = 'buttonRadio',
  inputNumber = 'inputNumber',
  select = 'select',
  pagination = 'pagination'
}

// Use one name ONLY ONCE for one common filters component
export enum UniqueFiltersStorageName {
  boosting = 'boosting',
  coaching = 'coaching'
}

export enum ReportType {
  Boosting = 'boosting',
  Coaching = 'coaching',
  OfferBuyer = 'offerbuyer',
  OfferSeller = 'offerseller',
  OfferBuyerAfterTransaction = 'offerbuyeraftertransaction'
}
