import {
  type UserMarketplaceProfileData,
  UserMarketplaceProfileEntity,
  type UserMarketplaceProfileResponseData
} from '@svelte/lib/entity/user-marketplace-profile.entity';

export const mapUserMarketplaceProfile = (
  data: UserMarketplaceProfileResponseData
): UserMarketplaceProfileEntity => {
  const userData: UserMarketplaceProfileData = {
    id: data.id,
    verified_seller: data.verified_seller,
    is_eb24_seller: data.is_eb24_seller
  };

  return new UserMarketplaceProfileEntity(userData);
};

export const mapUserMarketplaceProfiles = (
  data: UserMarketplaceProfileResponseData[]
): UserMarketplaceProfileEntity[] => {
  return data.map((item) => mapUserMarketplaceProfile(item));
};
