import { derived, readonly, writable, type Readable } from 'svelte/store';
import { UserRole, type Auth, type OriginUser } from '@svelte/lib/interface/auth';

let defaultAuth: Auth = null;

export const authWritable = writable(defaultAuth);
export const auth: Readable<Auth> = readonly(authWritable);

const defaultOriginUser: OriginUser = null;

export const originUserWritable = writable(defaultOriginUser);
export const originUser = readonly(originUserWritable);

export const isContractor = derived(auth, ($auth) => {
  return $auth !== null && $auth.role === UserRole.Booster;
});
