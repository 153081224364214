import { Game } from '@svelte/lib/enum/drivers/base';
import type { SelectOption } from '@svelte/lib/interface/types';

export function lolServerFriendlyNameByValue(
  serverValue: string,
  driversServerName: string
): string {
  switch (serverValue) {
    case 'lol_loc_br':
      return 'Brazil';

    case 'lol_loc_eune':
      return 'Europe Nordic & East';

    case 'lol_loc_euw':
      return 'Europe West';

    case 'lol_loc_me':
      return 'Middle East';

    case 'lol_loc_lan':
      return 'Latin America North';

    case 'lol_loc_las':
      return 'Latin America South';

    case 'lol_loc_na':
      return 'North America';

    case 'lol_loc_oce':
      return 'Oceania';

    case 'lol_loc_ru':
      return 'Russia';

    case 'lol_loc_tr':
      return 'Turkey';

    case 'lol_loc_jp':
      return 'Japan';

    default:
      return driversServerName;
  }
}

export function tftServerFriendlyNameByValue(
  serverValue: string,
  driversServerName: string
): string {
  switch (serverValue) {
    case 'tft_loc_br':
      return 'Brazil';

    case 'tft_loc_eune':
      return 'Europe Nordic & East';

    case 'tft_loc_euw':
      return 'Europe West';

    case 'lol_loc_me':
      return 'Middle East';

    case 'tft_loc_lan':
      return 'Latin America North';

    case 'tft_loc_las':
      return 'Latin America South';

    case 'tft_loc_na':
      return 'North America';

    case 'tft_loc_me':
      return 'Middle East';

    case 'tft_loc_oce':
      return 'Oceania';

    case 'tft_loc_ru':
      return 'Russia';

    case 'tft_loc_tr':
      return 'Turkey';

    case 'tft_loc_jp':
      return 'Japan';

    case 'tft_loc_kr': // TODO: remove Korea from TFT after in drivers it will be removed
      return 'Korea';

    default:
      return driversServerName;
  }
}

export function vrtServerFriendlyNameByValue(
  serverValue: string,
  driversServerName: string
): string {
  switch (serverValue) {
    case 'vrt_loc_eu':
      return 'Europe';
    case 'vrt_loc_na':
      return 'North America';
    case 'vrt_loc_oce':
      return 'Oceania';
    case 'vrt_loc_jp':
      return 'Japan';
    case 'vrt_loc_kr':
      return 'Korea';
    case 'vrt_loc_sa': // TODO: in current EB this server has different short name -> SEA (Southeast Asia)
      return 'Southeast Asia';
    default:
      return driversServerName;
  }
}

export function owServerFriendlyNameByValue(
  serverValue: string,
  driversServerName: string
): string {
  switch (serverValue) {
    case 'ow_loc_eu':
      return 'Europe';
    case 'ow_loc_america':
      return 'America';
    case 'ow_loc_asia-and-oceania':
      return 'Asia and Oceania';
    default:
      return driversServerName;
  }
}

export function userLocationFriendlyNameByValue(
  serverValue: string,
  driversServerName: string
): string {
  switch (serverValue) {
    case 'user_loc_eu':
      return 'Europe';
    case 'user_loc_na':
      return 'North America';
    case 'user_loc_oce':
      return 'Oceania';
    case 'user_loc_ru':
      return 'Russia';
    case 'user_loc_tr':
      return 'Turkey';
    case 'user_loc_mena':
      return 'MENA';
    case 'user_loc_a':
      return 'Asia';
    case 'user_loc_sa':
      return 'South America';
    case 'user_loc_af':
      return 'Africa';
    default:
      return driversServerName;
  }
}

export function getServerFiendlyNameByValue(
  game: Game,
  serverValue: string,
  driversServerName: string
): string {
  switch (game) {
    case Game.leagueoflegends:
      return lolServerFriendlyNameByValue(serverValue, driversServerName);
    case Game.teamfight_tactics:
      return tftServerFriendlyNameByValue(serverValue, driversServerName);
    case Game.valorant:
      return vrtServerFriendlyNameByValue(serverValue, driversServerName);
    case Game.overwatch:
      return owServerFriendlyNameByValue(serverValue, driversServerName);
    case Game.apex:
    case Game.rocket:
    case Game.cs:
      return userLocationFriendlyNameByValue(serverValue, driversServerName);
    case Game.wildrift:
      return wildriftServerFriendlyNameByValue(serverValue, driversServerName);
    default:
      console.log(`Unknown game value: ${game}`);
      return driversServerName;
  }
}

const lolServersOrder: string[] = [
  'lol_loc_euw',
  'lol_loc_eune',
  'lol_loc_na',
  'lol_loc_me',
  'lol_loc_oce',
  'lol_loc_ru',
  'lol_loc_tr',
  'lol_loc_lan',
  'lol_loc_las',
  'lol_loc_br',
  'lol_loc_jp'
];

const tftServersOrder: string[] = [
  'tft_loc_euw',
  'tft_loc_eune',
  'tft_loc_na',
  'tft_loc_me',
  'tft_loc_oce',
  'tft_loc_ru',
  'tft_loc_tr',
  'tft_loc_lan',
  'tft_loc_las',
  'tft_loc_br',
  'tft_loc_jp'
];

const vrtServersOrder: string[] = [
  'vrt_loc_eu',
  'vrt_loc_na',
  'vrt_loc_oce',
  'vrt_loc_jp',
  'vrt_loc_kr',
  'vrt_loc_sa'
];

const owServersOrder: string[] = ['ow_loc_eu', 'ow_loc_america', 'ow_loc_asia-and-oceania'];

const userServersOrder: string[] = [
  'user_loc_eu',
  'user_loc_na',
  'user_loc_oce',
  'user_loc_ru',
  'user_loc_tr',
  'user_loc_mena',
  'user_loc_a',
  'user_loc_sa',
  'user_loc_af'
];

function getServerOrderList(game: Game): string[] {
  switch (game) {
    case Game.leagueoflegends:
      return lolServersOrder;
    case Game.teamfight_tactics:
      return tftServersOrder;
    case Game.valorant:
      return vrtServersOrder;
    case Game.overwatch:
      return owServersOrder;
    case Game.apex:
    case Game.rocket:
    case Game.cs:
      return userServersOrder;
    case Game.wildrift:
      return [];
    default:
      console.error(`Unknown game value: ${game}`);
      return [];
  }
}

export function sortedServersByValue(game: Game, options: SelectOption[]): SelectOption[] {
  const orderList: string[] = getServerOrderList(game);
  if (!orderList.length) {
    return options;
  }

  return options.sort(({ value: a }: SelectOption, { value: b }: SelectOption): number => {
    return orderList.indexOf(a) - orderList.indexOf(b);
  });
}

function lolGetServerByCountryCode(countryCode: string): string {
  switch (countryCode.toUpperCase()) {
    case 'US':
    case 'CA':
      return 'lol_loc_na';
    case 'TR':
      return 'lol_loc_tr';
    case 'RU':
      return 'lol_loc_ru';
    case 'JP':
      return 'lol_loc_jp';
    case 'AU':
    case 'NZ':
    case 'ID':
    case 'MY':
    case 'SG':
    case 'AU-TAS':
    case 'PG':
      return 'lol_loc_oce';
    case 'BR':
      return 'lol_loc_br';
    case 'BS':
    case 'KY':
    case 'CO':
    case 'CR':
    case 'DO':
    case 'EC':
    case 'GF':
    case 'GP':
    case 'HT':
    case 'HN':
    case 'JM':
    case 'MX':
    case 'PA':
    case 'PE':
    case 'PR':
    case 'AI':
    case 'TT':
    case 'TC':
    case 'VG':
    case 'VI':
    case 'VE':
      return 'lol_loc_lan';
    case 'AR':
    case 'BO':
    case 'CL':
    case 'PY':
    case 'UY':
      return 'lol_loc_las';
    default:
      return 'lol_loc_euw';
  }
}

function tftGetServerByCountryCode(countryCode: string): string {
  switch (countryCode.toUpperCase()) {
    case 'US':
    case 'CA':
      return 'tft_loc_na';
    case 'TR':
      return 'tft_loc_tr';
    case 'RU':
      return 'tft_loc_ru';
    case 'JP':
      return 'tft_loc_jp';
    case 'AU':
    case 'NZ':
    case 'ID':
    case 'MY':
    case 'SG':
    case 'AU-TAS':
    case 'PG':
      return 'tft_loc_oce';
    case 'BR':
      return 'tft_loc_br';
    case 'BS':
    case 'KY':
    case 'CO':
    case 'CR':
    case 'DO':
    case 'EC':
    case 'GF':
    case 'GP':
    case 'HT':
    case 'HN':
    case 'JM':
    case 'MX':
    case 'PA':
    case 'PE':
    case 'PR':
    case 'AI':
    case 'TT':
    case 'TC':
    case 'VG':
    case 'VI':
    case 'VE':
      return 'tft_loc_lan';
    case 'AR':
    case 'BO':
    case 'CL':
    case 'PY':
    case 'UY':
      return 'tft_loc_las';
    default:
      return 'tft_loc_euw';
  }
}

function vrtGetServerByCountryCode(countryCode: string): string {
  switch (countryCode.toUpperCase()) {
    case 'TR':
    case 'RU':
      return 'vrt_loc_eu';
    case 'AU':
    case 'NZ':
    case 'ID':
    case 'MY':
    case 'SG':
    case 'AU-TAS':
    case 'PG':
      return 'vrt_loc_oce';
    case 'US':
    case 'CA':
    case 'JP':
    case 'KR':
    case 'BR':
    case 'BS':
    case 'KY':
    case 'CO':
    case 'CR':
    case 'DO':
    case 'EC':
    case 'GF':
    case 'GP':
    case 'HT':
    case 'HN':
    case 'JM':
    case 'MX':
    case 'PA':
    case 'PE':
    case 'PR':
    case 'AI':
    case 'TT':
    case 'TC':
    case 'VG':
    case 'VI':
    case 'VE':
    case 'AR':
    case 'BO':
    case 'CL':
    case 'PY':
    case 'UY':
      return 'vrt_loc_na';
    default:
      return 'vrt_loc_eu';
  }
}

function overwatchGetServerByCountryCode(countryCode: string): string {
  switch (countryCode.toUpperCase()) {
    case 'TR':
    case 'RU':
      return 'ow_loc_eu';
    case 'AU':
    case 'NZ':
    case 'ID':
    case 'MY':
    case 'SG':
    case 'AU-TAS':
    case 'PG':
      return 'ow_loc_asia-and-oceania';
    case 'US':
    case 'CA':
    case 'JP':
    case 'KR':
    case 'BR':
    case 'BS':
    case 'KY':
    case 'CO':
    case 'CR':
    case 'DO':
    case 'EC':
    case 'GF':
    case 'GP':
    case 'HT':
    case 'HN':
    case 'JM':
    case 'MX':
    case 'PA':
    case 'PE':
    case 'PR':
    case 'AI':
    case 'TT':
    case 'TC':
    case 'VG':
    case 'VI':
    case 'VE':
    case 'AR':
    case 'BO':
    case 'CL':
    case 'PY':
    case 'UY':
      return 'ow_loc_america';
    default:
      return 'ow_loc_eu';
  }
}

function userLocationByCountryCode(countryCode: string): string {
  switch (countryCode.toUpperCase()) {
    case 'DZ':
    case 'BH':
    case 'EG':
    case 'IR':
    case 'IQ':
    case 'JO':
    case 'KW':
    case 'LB':
    case 'LY':
    case 'MA':
    case 'OM':
    case 'QA':
    case 'SA':
    case 'SY':
    case 'TN':
    case 'AE':
    case 'YE':
      return 'user_loc_mena';
    case 'RU':
      return 'user_loc_ru';
    case 'TR':
      return 'user_loc_tr';
    case 'AT':
    case 'BE':
    case 'BG':
    case 'HR':
    case 'CY':
    case 'CZ':
    case 'DK':
    case 'EE':
    case 'FI':
    case 'FR':
    case 'DE':
    case 'GR':
    case 'HU':
    case 'IE':
    case 'IT':
    case 'LV':
    case 'LT':
    case 'LU':
    case 'MT':
    case 'NL':
    case 'PL':
    case 'PT':
    case 'RO':
    case 'SK':
    case 'SI':
    case 'ES':
    case 'SE':
    case 'GB':
      return 'user_loc_eu';
    case 'US':
    case 'CA':
      return 'user_loc_na';
    case 'AR':
    case 'BO':
    case 'BR':
    case 'CL':
    case 'CO':
    case 'EC':
    case 'GY':
    case 'PY':
    case 'PE':
    case 'SR':
    case 'UY':
    case 'VE':
      return 'user_loc_sa';
    case 'CN':
    case 'JP':
    case 'KR':
    case 'IN':
    case 'TH':
    case 'MY':
    case 'VN':
    case 'PH':
    case 'ID':
    case 'SG':
    case 'MM':
    case 'KH':
    case 'LA':
    case 'BN':
    case 'TL':
      return 'user_loc_a';
    case 'AU':
    case 'NZ':
    case 'FJ':
    case 'PG':
    case 'SB':
    case 'VU':
    case 'WS':
    case 'KI':
    case 'FM':
    case 'TO':
    case 'MH':
    case 'PW':
    case 'TV':
    case 'NR':
      return 'user_loc_oce';
    case 'ZA':
    case 'NG':
    case 'KE':
    case 'GH':
    case 'ET':
    case 'UG':
    case 'TZ':
    case 'ZW':
    case 'AO':
    case 'CM':
    case 'CI':
    case 'CD':
    case 'SD':
    case 'SN':
    case 'ML':
      return 'user_loc_af';
    default:
      return 'user_loc_eu';
  }
}

function wildriftServerByCountryCode(countryCode: string): string {
  switch (countryCode.toUpperCase()) {
    case 'AT':
    case 'BE':
    case 'BG':
    case 'HR':
    case 'CY': // Cyprus is geographically in Asia but culturally and politically often associated with Europe
    case 'CZ':
    case 'DK':
    case 'EE':
    case 'FI':
    case 'FR':
    case 'DE':
    case 'GR': // Greece is often associated with Europe but geographically borders the Middle East
    case 'HU':
    case 'IE':
    case 'IT':
    case 'LV':
    case 'LT':
    case 'LU':
    case 'MT':
    case 'NL':
    case 'PL':
    case 'PT':
    case 'RO':
    case 'SK':
    case 'SI':
    case 'ES':
    case 'SE':
    case 'GB':
      return 'wr_loc_eu'; // Europe
    case 'US':
    case 'CA':
      return 'wr_loc_na'; // North America
    case 'AU':
    case 'NZ':
    case 'FJ':
    case 'PG':
    case 'SB':
    case 'VU':
    case 'WS':
    case 'KI':
    case 'FM':
    case 'TO':
    case 'MH':
    case 'PW':
    case 'TV':
    case 'NR':
      return 'wr_loc_oce'; // Oceania
    case 'RU':
      return 'wr_loc_ru'; // Russia
    case 'TR':
    case 'IL': // Israel
    case 'AE': // United Arab Emirates
    case 'SA': // Saudi Arabia
    case 'JO': // Jordan
    case 'QA': // Qatar
    case 'KW': // Kuwait
    case 'BH': // Bahrain
    case 'OM': // Oman
    case 'LB': // Lebanon
    case 'SY': // Syria
    case 'IQ': // Iraq
    case 'YE': // Yemen
    case 'IR': // Iran
      return 'wr_loc_me'; // Middle East
    case 'BR':
    case 'AR': // Argentina
    case 'BO': // Bolivia
    case 'CL': // Chile
    case 'CO': // Colombia
    case 'CR': // Costa Rica
    case 'CU': // Cuba
    case 'DO': // Dominican Republic
    case 'EC': // Ecuador
    case 'SV': // El Salvador
    case 'GT': // Guatemala
    case 'HN': // Honduras
    case 'MX': // Mexico
    case 'NI': // Nicaragua
    case 'PA': // Panama
    case 'PY': // Paraguay
    case 'PE': // Peru
    case 'PR': // Puerto Rico
    case 'UY': // Uruguay
    case 'VE': // Venezuela
      return 'wr_loc_la'; // Latin America
    case 'JP':
      return 'wr_loc_jp'; // Japan
    case 'CN':
    case 'KR':
    case 'IN':
    case 'TH':
    case 'MY':
    case 'VN':
    case 'PH':
    case 'ID':
    case 'SG':
    case 'MM':
    case 'KH':
    case 'LA':
    case 'BN':
    case 'TL':
      return 'wr_loc_ap'; // Asia Pacific
    default:
      return 'wr_loc_eu'; // Default to Europe
  }
}

function wildriftServerFriendlyNameByValue(serverValue: string, driversServerName: string): string {
  switch (serverValue) {
    case 'wr_loc_eu':
      return 'Europe';
    case 'wr_loc_na':
      return 'North America';
    case 'wr_loc_oce':
      return 'Oceania';
    case 'wr_loc_me':
      return 'Middle East';
    case 'wr_loc_ru':
      return 'Russia';
    case 'wr_loc_la':
      return 'Latin America';
    case 'wr_loc_jp':
      return 'Japan';
    case 'wr_loc_ap':
      return 'Asia Pacific';
    default:
      return driversServerName;
  }
}

export function getServerByGameAndCountryCode(game: Game, countryCode: string): string {
  switch (game) {
    case Game.leagueoflegends:
      return lolGetServerByCountryCode(countryCode);
    case Game.teamfight_tactics:
      return tftGetServerByCountryCode(countryCode);
    case Game.valorant:
      return vrtGetServerByCountryCode(countryCode);
    case Game.overwatch:
      return overwatchGetServerByCountryCode(countryCode);
    case Game.apex:
    case Game.rocket:
    case Game.cs:
      return userLocationByCountryCode(countryCode);
    case Game.wildrift:
      return wildriftServerByCountryCode(countryCode);
    default:
      console.log(`Unknown game value: ${game}`);
      return '';
  }
}
