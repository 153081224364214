import { Game } from '@svelte/lib/enum/drivers/base';

export const getQueueTypeLabel = (game: Game, queueType: string): string => {
  switch (game) {
    case Game.leagueoflegends:
      return lolQueueTypeLabel(queueType);
    case Game.valorant:
      return vrtQueueTypeLabel(queueType);
    case Game.teamfight_tactics:
      return tftQueueTypeLabel(queueType);
    case Game.overwatch:
      return owQueueTypeLabel(queueType);
    case Game.rocket:
      return rocketQueueTypeLabel(queueType);
    default:
      console.log('getQueueTypeLabel -> Unknown game: ' + game);
      return queueType.replace(/_/g, ' ');
  }
};

export const lolQueueTypeLabel = (queueType: string): string => {
  switch (queueType) {
    case 'Solo/Duo':
      return 'Solo/Duo';
    case 'Flex':
      return 'Flex';
    case 'Blind/Draft':
      return 'Blind/Draft';
    case 'Aram':
      return 'Aram';
    default:
      console.log('lolQueueTypeLabel -> Unknown queue type: ' + queueType);
      return queueType.replace(/_/g, ' ');
  }
};

export const vrtQueueTypeLabel = (queueType: string): string => {
  switch (queueType) {
    case 'NormalMatch':
      return 'Normal Match';
    case 'RankedMatch':
      return 'Ranked Match';
    default:
      console.log('vrtQueueTypeLabel -> Unknown queue type: ' + queueType);
      return queueType.replace(/_/g, ' ');
  }
};

export const tftQueueTypeLabel = (queueType: string): string => {
  switch (queueType) {
    case 'Regular':
      return 'Regular';
    case 'DoubleUp':
      return 'Double up';
    default:
      console.log('tftQueueTypeLabel -> Unknown queue type: ' + queueType);
      return queueType.replace(/_/g, ' ');
  }
};

export const owQueueTypeLabel = (queueType: string): string => {
  switch (queueType) {
    case 'QuickPlay':
      return 'Quick play';
    case 'RankedMatch':
      return 'Ranked match';
    default:
      console.log('owQueueTypeLabel -> Unknown queue type: ' + queueType);
      return queueType.replace(/_/g, ' ');
  }
};

export const rocketQueueTypeLabel = (queueType: string): string => {
  switch (queueType) {
    case 'solo_duel_1v1':
      return 'Solo Duel (1v1)';
    case 'Doubles (2v2)':
      return 'Doubles (2v2)';
    case 'standard_3v3':
      return 'Standard (3v3)';
    case 'snow_day':
      return 'Snow Day';
    case 'rocket_labs':
      return 'Rocket Labs';
    case 'hoops':
      return 'Hoops';
    case 'rumble':
      return 'Rumble';
    case 'dropshot':
      return 'Dropshot';
    default:
      console.log('rocketQueueTypeLabel -> Unknown queue type: ' + queueType);
      return queueType.replace(/_/g, ' ');
  }
};
