import { createInertiaApp, router } from '@inertiajs/svelte';
import { authWritable, originUserWritable } from '@svelte/lib/store/auth';
import {
  setupGlobals,
  setupActionTouch,
  setupWebsocketFlashListener,
  addToastNotificationApiToWindow
} from '@js/modules/global.js';
import { canPlaySound } from '@svelte/lib/store/common.ts';
import { setupUserInteractWithPageEvent } from '@js/helpers/user_interact_with_page_event.js';
import { tidioScriptWithDelay } from '@svelte/lib/helpers/tidioScript';
import * as Sentry from '@sentry/svelte';
import { initPusherAvailableOffers } from '@svelte/lib/helpers/dashboard/avilable-offers';
import { UserRole } from '@svelte/lib/interface/auth';

if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_JS_DSN,
    environment: import.meta.env.VITE_APP_ENV
  });

  if (typeof _SENTRY_JS_DATA !== 'undefined') {
    Sentry.setUser(_SENTRY_JS_DATA);
  }
}

setupGlobals();
addToastNotificationApiToWindow();

window.addEventListener('DOMContentLoaded', function () {
  setupUserInteractWithPageEvent();
  window.addEventListener('user_interact_with_page', () => {
    canPlaySound.set(true);
  });
  setupActionTouch();
  setupWebsocketFlashListener();
});

let unsubscribePusher = () => {};

tidioScriptWithDelay();

router.on('navigate', (event) => {
  const auth = event.detail.page.props.auth;
  authWritable.set(auth);
  originUserWritable.set(event.detail.page.props.originUser);

  const isContractor = auth !== null && auth.role === UserRole.Booster;
  if (isContractor) {
    unsubscribePusher = initPusherAvailableOffers();
  } else {
    unsubscribePusher();
  }
});
// partial reload
router.on('success', (event) => {
  const auth = event.detail.page.props.auth;
  authWritable.set(auth);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'spa_page_view',
    pageUrl: window.location.href,
    pagePath: window.location.pathname,
    pageTitle: window.document.title
  });

  const isContractor = auth !== null && auth.role === UserRole.Booster;
  if (isContractor) {
    unsubscribePusher = initPusherAvailableOffers();
  } else {
    unsubscribePusher();
  }
});

createInertiaApp({
  progress: {
    color: '#FFEE00'
  },
  resolve: (name) => {
    let pages = import.meta.glob(['./pages/**/*.svelte']);

    // exclude new dashboard pages from production build
    if (import.meta.env.VITE_APP_ENV === 'production') {
      pages = import.meta.glob(['./pages/**/*.svelte', '!./pages/dashboard/**/*.svelte']);
    }

    return pages[`./pages/${name}.svelte`]();
  },
  setup({ el, App }) {
    new App({ target: el, hydrate: true });
  }
});
