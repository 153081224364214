export enum SortingAvailableOffers {
  newest = '-created_at',
  oldest = 'created_at',
  priceHighToLow = '-price',
  priceLowToHigh = 'price'
}

export enum MacroDetectionMethod {
  Unknown = 0,
  turnstileFrontend = 1,
  turnstileBackend = 2,
  isTrusted = 3
}
