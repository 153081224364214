import { Game } from '@svelte/lib/enum/drivers/base';

export const isLolServer = (server: string): server is LolServers => {
  return Object.values(LolServers).includes(server as LolServers);
};

export enum LolServers {
  BR = 'lol_loc_br',
  EUNE = 'lol_loc_eune',
  EUW = 'lol_loc_euw',
  ME = 'lol_loc_me',
  LAN = 'lol_loc_lan',
  LAS = 'lol_loc_las',
  NA = 'lol_loc_na',
  OCE = 'lol_loc_oce',
  RU = 'lol_loc_ru',
  TR = 'lol_loc_tr',
  JP = 'lol_loc_jp'
}

export function getLolServerLabel(server: string): string {
  if (!isLolServer(server)) {
    return '';
  }

  switch (server) {
    case LolServers.BR:
      return 'Brazil';
    case LolServers.EUNE:
      return 'Europe Nordic & East';
    case LolServers.EUW:
      return 'Europe West';
    case LolServers.ME:
      return 'Middle East';
    case LolServers.LAN:
      return 'Latin America North';
    case LolServers.LAS:
      return 'Latin America South';
    case LolServers.NA:
      return 'North America';
    case LolServers.OCE:
      return 'Oceania';
    case LolServers.RU:
      return 'Russia';
    case LolServers.TR:
      return 'Turkey';
    case LolServers.JP:
      return 'Japan';
    default:
      return '';
  }
}

export const isOwServer = (server: string): server is OverwatchServers => {
  return Object.values(OverwatchServers).includes(server as OverwatchServers);
};

export enum OverwatchServers {
  EU = 'ow_loc_eu',
  AMERICA = 'ow_loc_america',
  ASIA_AND_OCEANIA = 'ow_loc_asia-and-oceania'
}

export function getOwServerLabel(server: string): string {
  if (!isOwServer(server)) {
    return '';
  }

  switch (server) {
    case OverwatchServers.EU:
      return 'Europe';
    case OverwatchServers.AMERICA:
      return 'America';
    case OverwatchServers.ASIA_AND_OCEANIA:
      return 'Asia & Oceania';
    default:
      return '';
  }
}

export const isTftServer = (server: string): server is TftServers => {
  return Object.values(TftServers).includes(server as TftServers);
};

export enum TftServers {
  BR = 'tft_loc_br',
  EUNE = 'tft_loc_eune',
  EUW = 'tft_loc_euw',
  LAN = 'tft_loc_lan',
  ME = 'tft_loc_me',
  LAS = 'tft_loc_las',
  NA = 'tft_loc_na',
  OCE = 'tft_loc_oce',
  RU = 'tft_loc_ru',
  TR = 'tft_loc_tr',
  JP = 'tft_loc_jp'
}

export function getTftServerLabel(server: string): string {
  if (!isTftServer(server)) {
    return '';
  }

  switch (server) {
    case TftServers.BR:
      return 'Brazil';
    case TftServers.EUNE:
      return 'Europe Nordic & East';
    case TftServers.EUW:
      return 'Europe West';
    case TftServers.LAN:
      return 'Latin America North';
    case TftServers.ME:
      return 'Middle East';
    case TftServers.LAS:
      return 'Latin America South';
    case TftServers.NA:
      return 'North America';
    case TftServers.OCE:
      return 'Oceania';
    case TftServers.RU:
      return 'Russia';
    case TftServers.TR:
      return 'Turkey';
    case TftServers.JP:
      return 'Japan';
    default:
      return '';
  }
}

export const isValorantServer = (server: string): server is ValorantServers => {
  return Object.values(ValorantServers).includes(server as ValorantServers);
};

export enum ValorantServers {
  EU = 'vrt_loc_eu',
  NA = 'vrt_loc_na',
  OCE = 'vrt_loc_oce',
  JP = 'vrt_loc_jp',
  KR = 'vrt_loc_kr',
  SA = 'vrt_loc_sa'
}

export function getValorantServerLabel(server: string): string {
  if (!isValorantServer(server)) {
    return '';
  }

  switch (server) {
    case ValorantServers.EU:
      return 'Europe';
    case ValorantServers.NA:
      return 'North America';
    case ValorantServers.OCE:
      return 'Oceania';
    case ValorantServers.JP:
      return 'Japan';
    case ValorantServers.KR:
      return 'Korea';
    case ValorantServers.SA:
      return 'South America';
    default:
      return '';
  }
}

export const isWildRiftServer = (server: string): server is WildRiftServers => {
  return Object.values(WildRiftServers).includes(server as WildRiftServers);
};

export enum WildRiftServers {
  EU = 'wr_loc_eu',
  NA = 'wr_loc_na',
  OCE = 'wr_loc_oce',
  ME = 'wr_loc_me',
  RU = 'wr_loc_ru',
  JP = 'wr_loc_jp',
  LA = 'wr_loc_la',
  AP = 'wr_loc_ap'
}

export function getWildRiftServerLabel(server: string): string {
  if (!isWildRiftServer(server)) {
    return '';
  }

  switch (server) {
    case WildRiftServers.EU:
      return 'Europe';
    case WildRiftServers.NA:
      return 'North America';
    case WildRiftServers.OCE:
      return 'Oceania';
    case WildRiftServers.ME:
      return 'Middle East';
    case WildRiftServers.RU:
      return 'Russia';
    case WildRiftServers.JP:
      return 'Japan';
    case WildRiftServers.LA:
      return 'Latin America';
    case WildRiftServers.AP:
      return 'Asia Pacific';
    default:
      return '';
  }
}

export const isUserLocationEnum = (server: string): server is UserLocationEnum => {
  return Object.values(UserLocationEnum).includes(server as UserLocationEnum);
};

// used in Game.apex, Game.cs, Game.rocket
export enum UserLocationEnum {
  MENA = 'user_loc_mena',
  RU = 'user_loc_ru',
  TR = 'user_loc_tr',
  EU = 'user_loc_eu',
  NA = 'user_loc_na',
  SA = 'user_loc_sa',
  A = 'user_loc_a',
  OCE = 'user_loc_oce',
  AF = 'user_loc_af'
}

export function getUserLocationLabel(server: string): string {
  if (!isUserLocationEnum(server)) {
    return '';
  }

  switch (server) {
    case UserLocationEnum.MENA:
      return 'Middle East & North Africa';
    case UserLocationEnum.RU:
      return 'Russia';
    case UserLocationEnum.TR:
      return 'Turkey';
    case UserLocationEnum.EU:
      return 'Europe';
    case UserLocationEnum.NA:
      return 'North America';
    case UserLocationEnum.SA:
      return 'South America';
    case UserLocationEnum.A:
      return 'Asia';
    case UserLocationEnum.OCE:
      return 'Oceania';
    case UserLocationEnum.AF:
      return 'Africa';
    default:
      return '';
  }
}

export const getServerLabel = (game: Game, server: string): string => {
  switch (game) {
    case Game.leagueoflegends:
      return getLolServerLabel(server);
    case Game.overwatch:
      return getOwServerLabel(server);
    case Game.teamfight_tactics:
      return getTftServerLabel(server);
    case Game.valorant:
      return getValorantServerLabel(server);
    case Game.wildrift:
      return getWildRiftServerLabel(server);
    case Game.apex:
    case Game.cs:
    case Game.rocket:
      return getUserLocationLabel(server);
    default:
      return '';
  }
};

export const getServerEnumKey = (game: Game | string, server: string): string => {
  switch (game as Game) {
    case Game.leagueoflegends:
      return Object.keys(LolServers)[Object.values(LolServers).indexOf(server as any)];
    case Game.overwatch:
      return Object.keys(OverwatchServers)[Object.values(OverwatchServers).indexOf(server as any)];
    case Game.teamfight_tactics:
      return Object.keys(TftServers)[Object.values(TftServers).indexOf(server as any)];
    case Game.valorant:
      return Object.keys(ValorantServers)[Object.values(ValorantServers).indexOf(server as any)];
    case Game.wildrift:
      return Object.keys(WildRiftServers)[Object.values(WildRiftServers).indexOf(server as any)];
    case Game.apex:
    case Game.cs:
    case Game.rocket:
      return Object.keys(UserLocationEnum)[Object.values(UserLocationEnum).indexOf(server as any)];
    default:
      return '';
  }
};
