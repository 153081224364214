import { BaseEntity } from '@svelte/lib/entity/base.entity';
import type { UserRole } from '@svelte/lib/interface/auth';
import type { OfferResponseData } from '@svelte/lib/entity/offer.entity';
import type { Review } from '@svelte/lib/interface/review';
import {
  UserMarketplaceProfileEntity,
  type UserMarketplaceProfileResponseData
} from '@svelte/lib/entity/user-marketplace-profile.entity';

export interface UserResponseData {
  id: number;
  name: string;
  avatar: string;
  role: UserRole;
  isActive: boolean;
  hasDiscord: boolean;
  offers?: OfferResponseData[];
  offersCount: number;
  review?: Review;
  marketplaceProfile?: UserMarketplaceProfileResponseData;
}

export interface UserData {
  id: number;
  name: string;
  avatar: string;
  role: UserRole;
  isActive: boolean;
  hasDiscord: boolean;
  offersCount: number;
  review?: Review;
  marketplaceTransactions?: number;
  marketplaceProfile?: UserMarketplaceProfileEntity;
}

export class UserEntity extends BaseEntity<UserData> {
  get id() {
    return this.data.id;
  }
  get name() {
    return this.data.name;
  }
  get avatar() {
    return this.data.avatar;
  }
  get offersCount() {
    return this.data.offersCount;
  }
  get review() {
    return this.data.review;
  }
  get marketplaceTransactions() {
    return this.data.marketplaceTransactions;
  }
  get marketplaceProfile() {
    return this.data.marketplaceProfile;
  }
  get isActive() {
    return this.data.isActive;
  }
  get hasDiscord() {
    return this.data.hasDiscord;
  }
}
