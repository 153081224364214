import { get, readonly, writable, type Writable } from 'svelte/store';
import type { AvailableOfferEntity } from '@svelte/lib/entity/available-offer.entity';

const searchParamsAvailableOffersBoostingWritable = writable(new URLSearchParams());
export const searchParamsAvailableOffersBoosting = readonly(
  searchParamsAvailableOffersBoostingWritable
);

export const setSearchParamsAvailableOffersBoosting = (searchParams: URLSearchParams) => {
  searchParamsAvailableOffersBoostingWritable.set(searchParams);
};

const searchParamsAvailableOffersCoachingWritable = writable(new URLSearchParams());
export const searchParamsAvailableOffersCoaching = readonly(
  searchParamsAvailableOffersCoachingWritable
);

export const setSearchParamsAvailableOffersCoaching = (searchParams: URLSearchParams) => {
  searchParamsAvailableOffersCoachingWritable.set(searchParams);
};

const uniqueNamesActiveEb24PusherListenersWritable = writable(new Set());
export const uniqueNamesActiveEb24PusherListeners = readonly(
  uniqueNamesActiveEb24PusherListenersWritable
);

export const isActiveEb24PusherListener = (uniqueName: string) => {
  return get(uniqueNamesActiveEb24PusherListeners).has(uniqueName);
};

export const addActiveEb24PusherListener = (uniqueName: string) => {
  uniqueNamesActiveEb24PusherListenersWritable.update((uniqueNames) => {
    uniqueNames.add(uniqueName);
    return uniqueNames;
  });
};

export const removeActiveEb24PusherListener = (uniqueName: string) => {
  uniqueNamesActiveEb24PusherListenersWritable.update((uniqueNames) => {
    uniqueNames.delete(uniqueName);
    return uniqueNames;
  });
};

const newBoostingOffersWritable: Writable<AvailableOfferEntity[]> = writable([]);
export const newBoostingOffers = readonly(newBoostingOffersWritable);
const newBoostingOffersToShowWritable: Writable<AvailableOfferEntity[]> = writable([]);
export const newBoostingOffersToShow = readonly(newBoostingOffersToShowWritable);

export const addNewBoostingOffer = (offer: AvailableOfferEntity) => {
  newBoostingOffersWritable.update((offers) => {
    offers.push(offer);
    return offers;
  });
};

export const clearNewBoostingOffers = () => {
  newBoostingOffersWritable.set([]);
  newBoostingOffersToShowWritable.set([]);
};

export const showNewBoostingOffers = () => {
  const newBoostingOffers = get(newBoostingOffersWritable);
  const newBoostingOffersToShow = get(newBoostingOffersToShowWritable);
  newBoostingOffersToShowWritable.set([...newBoostingOffersToShow, ...newBoostingOffers]);

  newBoostingOffersWritable.set([]);
};

const newCoachingOffersWritable: Writable<AvailableOfferEntity[]> = writable([]);
export const newCoachingOffers = readonly(newCoachingOffersWritable);
const newCoachingOffersToShowWritable: Writable<AvailableOfferEntity[]> = writable([]);
export const newCoachingOffersToShow = readonly(newCoachingOffersToShowWritable);

export const addNewCoachingOffer = (offer: AvailableOfferEntity) => {
  newCoachingOffersWritable.update((offers) => {
    offers.push(offer);
    return offers;
  });
};

export const clearNewCoachingOffers = () => {
  newCoachingOffersWritable.set([]);
  newCoachingOffersToShowWritable.set([]);
};

export const showNewCoachingOffers = () => {
  const newCoachingOffers = get(newCoachingOffersWritable);
  const newCoachingOffersToShow = get(newCoachingOffersToShowWritable);
  newCoachingOffersToShowWritable.set([...newCoachingOffersToShow, ...newCoachingOffers]);

  newCoachingOffersWritable.set([]);
};
