import type { UserData, UserResponseData } from '@svelte/lib/entity/user.entity';
import { UserEntity } from '@svelte/lib/entity/user.entity';
import type { Reviews } from '@svelte/lib/interface/review';
import type { UserOfferTransaction } from '@svelte/lib/interface/user-offer-transaction';
import { mapUserMarketplaceProfile } from '@svelte/lib/mapper/user-marketplace-profile.mapper';

export const mapUser = (
  data: UserResponseData,
  reviews?: Reviews,
  transactions?: UserOfferTransaction
): UserEntity => {
  const userData: UserData = {
    id: data.id,
    name: data.name,
    avatar: data.avatar,
    role: data.role,
    isActive: data.isActive,
    hasDiscord: data.hasDiscord,
    offersCount: data.offers ? data.offers.length : 0,
    review: reviews && reviews[data.id] ? reviews[data.id] : undefined,
    marketplaceTransactions:
      transactions && transactions[data.id] ? transactions[data.id] : undefined,
    marketplaceProfile: data.marketplaceProfile
      ? mapUserMarketplaceProfile(data.marketplaceProfile)
      : undefined
  };

  return new UserEntity(userData);
};

export const mapUsers = (data: UserResponseData[]): UserEntity[] => {
  return data.map((userItem) => mapUser(userItem));
};
