export enum CoachingStatus {
  WaitingForPayment = 0, // no payment
  WaitingForCoach = 1, // paid, waiting for coach, coach is not assigned
  InProgress = 2, // service in progress
  Ended = 3, // completed
  Abandoned = 4, // abandoned by coach, alternatively a customer requested new booster, waiting for admin action
  ReservedForCoach = 5, // reserved for the chosen booster, not available for the rest
  Archived = 6, // completed, 48 hours after the order completion
  WaitingForDetails = 7, // order created, paid, waiting for customer to provide details
  Canceled = 8 // order canceled, payment has been reverted
}

export const isCoachingStatus = (status: number): status is CoachingStatus => {
  return Object.values(CoachingStatus).includes(status as CoachingStatus);
};

export const getCoachingStatusLabel = (status: number): string => {
  if (!isCoachingStatus(status)) {
    console.log('Unknown coaching status:', status);
    return 'Uknown coaching status';
  }

  switch (status) {
    case CoachingStatus.WaitingForPayment:
      return 'Waiting for payment';
    case CoachingStatus.WaitingForCoach:
      return 'Waiting for coach';
    case CoachingStatus.InProgress:
      return 'In progress';
    case CoachingStatus.Ended:
      return 'Ended';
    case CoachingStatus.Abandoned:
      return 'Abandoned';
    case CoachingStatus.ReservedForCoach:
      return 'Reserved for coach';
    case CoachingStatus.Archived:
      return 'Archived';
    case CoachingStatus.WaitingForDetails:
      return 'Waiting for details';
    case CoachingStatus.Canceled:
      return 'Canceled';
    default:
      console.log('Unknown coaching status:', status);
      return 'Unknown status';
  }
};
