import {
  type AvailableOfferResponseData,
  type AvailableOfferData,
  AvailableOfferEntity
} from '@svelte/lib/entity/available-offer.entity';
import { Currency } from '@svelte/lib/enum/common';
import { BoostStatus } from '@svelte/lib/enum/dashboard/boost-status';
import { CoachingStatus } from '@svelte/lib/enum/dashboard/coaching-status';
import { DriverServiceType, getDriverServiceTypeLabel } from '@svelte/lib/enum/drivers/base';
import {
  getAvailableOfferTitle,
  getLabelCurrent,
  getLabelDesired,
  hasServer
} from '@svelte/lib/helpers/dashboard/avilable-offers';
import { getServerFiendlyNameByValue } from '@svelte/lib/helpers/drivers/servers';
import { mapUser } from '@svelte/lib/mapper/user.mapper';

export const mapperAvailableOffer = (data: AvailableOfferResponseData): AvailableOfferEntity => {
  const {
    id,
    price,
    booster_id,
    status,
    created_at,
    updated_at,
    enquiry,
    user,
    currency,
    percentage,
    description,
    available,
    available_error,
    available_expire_at,
    reviewHash,
    orderHash
  } = data;

  const game = enquiry.game.value;
  const shouldHasServer = hasServer(enquiry);
  const { serverLocation, isDuo, duoPremium, priorityQueue, serviceType } = enquiry;
  const serverLabel =
    shouldHasServer && serverLocation
      ? getServerFiendlyNameByValue(game, serverLocation.value, serverLocation.name)
      : '';
  const isCoaching = serviceType.value === DriverServiceType.cmm_cch_default;
  const isReserved = isCoaching
    ? status === CoachingStatus.ReservedForCoach
    : status === BoostStatus.ReservedForBooster;

  const availableExpireAt = available_expire_at
    ? new Date(available_expire_at).toLocaleString()
    : null;

  const availableError =
    availableExpireAt && available_error
      ? `${available_error}, the reservation expires at: ${availableExpireAt}`
      : available_error;

  const currentLabel = getLabelCurrent(enquiry);
  const desiredLabel = getLabelDesired(enquiry);

  const mappedData: AvailableOfferData = {
    id,
    isCoaching,
    price: price.toFixed(2),
    boosterId: booster_id,
    status,
    isReserved,
    createdAt: new Date(created_at).toLocaleString(),
    enquiry,
    user: mapUser(user),
    currency,
    currencySymbol: currency === Currency.EUR ? '€' : '$',
    percentage,
    rate: (Number(percentage) * 100).toFixed(),
    priceContractor: (price * Number(percentage)).toFixed(2),
    description,
    available,
    availableError,
    availableExpireAt,
    title: getAvailableOfferTitle(enquiry, currentLabel, desiredLabel, true),
    gameLabel: data.enquiry.game.label,
    serviceTypeLabel: getDriverServiceTypeLabel(serviceType.value),
    shouldHasServer,
    serverLabel: serverLabel || 'Not provided',
    isPriority: priorityQueue,
    typeOfCoach: enquiry.typeOfCoach?.name,
    currentLabel,
    desiredLabel,
    reviewHash,
    orderHash
  };

  if (isDuo && duoPremium !== undefined) {
    const duoType = duoPremium ? 'Premium' : 'Regular';

    mappedData.duoType = duoType;
    mappedData.titleDuo = `Duo ${duoType}`;
  }

  return new AvailableOfferEntity(mappedData);
};

export const mapperAvailableOffers = (
  data: AvailableOfferResponseData[]
): AvailableOfferEntity[] => {
  return data.map((item) => mapperAvailableOffer(item));
};
