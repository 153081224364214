import { get_eb24_pusher } from '@js/helpers/get_eb24_pusher.js';
import soundNewBoost from '@assets/static/sounds/newBoost_ariana.mp3';
import soundNewCoaching from '@assets/static/sounds/newCoaching.mp3';
import {
  BOOSTING_UNIQUE_STORAGE_NAME,
  COACHING_UNIQUE_STORAGE_NAME,
  filterIsDuoName,
  filterStateName,
  filterStateNoAcess,
  filterStateReadyToTake,
  filterStateReserved,
  filterTypeOfCoachName,
  gameFilterName,
  getBoostingSearchParams,
  getCoachingSearchParams,
  serverFilterName
} from '@svelte/lib/const/dashboard/filters';
import {
  addActiveEb24PusherListener,
  addNewBoostingOffer,
  addNewCoachingOffer,
  isActiveEb24PusherListener,
  removeActiveEb24PusherListener,
  searchParamsAvailableOffersBoosting,
  searchParamsAvailableOffersCoaching
} from '@svelte/lib/store/available-offers';
import { displayInfoNotification } from '@js/modules/notifications';
import type { UniqueFiltersStorageName } from '@svelte/lib/enum/dashboard/common';
import {
  AvailableOfferEntity,
  type AvailableOfferEnquiryResponseData,
  type AvailableOfferResponseData,
  type OfferAvailablityResponse
} from '@svelte/lib/entity/available-offer.entity';
import { route } from '@svelte/lib/helpers/route';
import { get } from 'svelte/store';
import { isFilterWithMuplipleOptionsActive } from '@svelte/lib/helpers/dashboard/commonSortingAndFilters';
import { boolToStringNumber } from '@svelte/lib/helpers/boolToStringNumber';
import { DriverServiceType, Game } from '@svelte/lib/enum/drivers/base';
import { isNumberAndNotNaN } from '@svelte/lib/mapper/enquiry.mapper';
import { rankQueueTypeInitialLabel } from '@svelte/lib/helpers/drivers/purchaseHeader';
import { getLeagueDivisionLabel } from '@svelte/lib/helpers/drivers/leagueDivision';
import { getMasteryLevelLabel } from '@svelte/lib/helpers/drivers/masteryLevel';
import { mapperAvailableOffer } from '@svelte/lib/mapper/dashboard/available-offer.mapper';

interface OptionsPusherAvailableOrders {
  pusherChannel: string;
  pusherEvent: string;
  notificationSound: string;
  notificationTitle: string;
  getUserLastSearchParams: () => URLSearchParams;
  offerType: UniqueFiltersStorageName;
  checkRouteName: string;
  getSearchParams: () => URLSearchParams;
  addNewOffer: (offer: AvailableOfferEntity) => void;
}

export const boostingOptions: OptionsPusherAvailableOrders = {
  pusherChannel: 'boosts',
  pusherEvent: 'NewBoostSvelte',
  notificationSound: soundNewBoost,
  notificationTitle: 'New Boosting order',
  getUserLastSearchParams: getBoostingSearchParams,
  offerType: BOOSTING_UNIQUE_STORAGE_NAME,
  checkRouteName: 'svelte_booster_check_boost',
  getSearchParams: () => get(searchParamsAvailableOffersBoosting),
  addNewOffer: addNewBoostingOffer
};

export const coachingOptions: OptionsPusherAvailableOrders = {
  pusherChannel: 'new_coaching',
  pusherEvent: 'NewCoachingSvelte',
  notificationSound: soundNewCoaching,
  notificationTitle: 'New Coaching order',
  getUserLastSearchParams: getCoachingSearchParams,
  offerType: COACHING_UNIQUE_STORAGE_NAME,
  checkRouteName: 'svelte_booster_check_coaching',
  getSearchParams: () => get(searchParamsAvailableOffersCoaching),
  addNewOffer: addNewCoachingOffer
};

export function pusherAvailableOrders(options: OptionsPusherAvailableOrders) {
  const uniquePusherListenerName = `${options.pusherChannel}_${options.pusherEvent}`;
  if (isActiveEb24PusherListener(uniquePusherListenerName)) {
    return () => {};
  }

  get_eb24_pusher()
    .private(options.pusherChannel)
    .listen(options.pusherEvent, (offer: AvailableOfferResponseData) =>
      newOfferHandler(offer, options)
    );

  addActiveEb24PusherListener(uniquePusherListenerName);

  return () => {
    get_eb24_pusher().private(options.pusherChannel).stopListening(options.pusherEvent);
    removeActiveEb24PusherListener(uniquePusherListenerName);
  };
}

async function newOfferHandler(
  offer: AvailableOfferResponseData,
  options: OptionsPusherAvailableOrders
) {
  console.log('New offer', offer);

  const searchParams = options.getSearchParams();
  let availableOfferEntity = mapperAvailableOffer(offer);

  // check base filters
  let isOfferShouldBeShown = checkIsOfferShouldBeShownByBaseFilters(
    options,
    availableOfferEntity,
    searchParams
  );

  if (!isOfferShouldBeShown) {
    return;
  }

  const availableData: OfferAvailablityResponse | undefined = await getAvailablityData(
    offer.id,
    options.checkRouteName
  );

  // if request failed show new offer anyway !!!
  if (availableData === undefined) {
    isOfferShouldBeShown = true;
  } else {
    patchOfferAvailability(offer, availableData);
    availableOfferEntity = mapperAvailableOffer(offer);
    isOfferShouldBeShown = checkIsFilterStateMatches(availableOfferEntity, searchParams);
  }

  if (!isOfferShouldBeShown) {
    return;
  }

  const sound = new Audio(options.notificationSound);
  // TODO: add sending native notification
  // send_notification(options.notificationTitle, order, sound);
  displayInfoNotification(options.notificationTitle, sound);
  options.addNewOffer(availableOfferEntity);
}

export function initPusherAvailableOffers() {
  const unsubscribeBoosting = pusherAvailableOrders(boostingOptions);
  const unsubscribeCoaching = pusherAvailableOrders(coachingOptions);

  return () => {
    unsubscribeBoosting();
    unsubscribeCoaching();
  };
}

function isOfferMatchesFilter(
  filterName: AvailableFilterNames,
  searchParam: URLSearchParams,
  availableOfferEntity: AvailableOfferEntity
) {
  if (searchParam.size === 0) {
    console.error('EB24: searchParam is empty');
    return true;
  }

  const filterValue = getFilterValueFromAvailableOffer(filterName, availableOfferEntity);

  if (filterValue === null) {
    return true;
  }

  const isFilterMatch = isFilterWithMuplipleOptionsActive(searchParam, filterName, filterValue);

  return isFilterMatch;
}

function checkIsOfferShouldBeShownByBaseFilters(
  options: OptionsPusherAvailableOrders,
  availableOfferEntity: AvailableOfferEntity,
  searchParams: URLSearchParams
) {
  const { offerType } = options;
  const { isCoaching } = availableOfferEntity;

  const checks = [
    isOfferMatchesFilter(gameFilterName, searchParams, availableOfferEntity),
    isOfferMatchesFilter(serverFilterName, searchParams, availableOfferEntity)
  ];

  // boosting specific filters
  if (offerType === BOOSTING_UNIQUE_STORAGE_NAME && !isCoaching) {
    checks.push(isOfferMatchesFilter(filterIsDuoName, searchParams, availableOfferEntity));
  }

  // coaching specific filters
  if (offerType === COACHING_UNIQUE_STORAGE_NAME && isCoaching) {
    checks.push(isOfferMatchesFilter(filterTypeOfCoachName, searchParams, availableOfferEntity));
  }

  return checks.every((check) => check);
}

async function getAvailablityData(
  id: number,
  checkRouteName: string
): Promise<OfferAvailablityResponse | undefined> {
  const res = await fetch(route(checkRouteName, id))
    .then(function (response) {
      if (response.ok) {
        return response.json();
      }
    })
    .then(function (res) {
      return res;
    })
    .catch(function (error) {
      console.error('EB24: Request check_offer_state failed', error);
    });

  return res;
}

function patchOfferAvailability(
  offer: AvailableOfferResponseData,
  availableData: OfferAvailablityResponse
) {
  offer.available = availableData.available;
  offer.available_expire_at = availableData.available_expire_at;
  offer.available_error = availableData.available_error;
}

function checkIsFilterStateMatches(
  availableOfferEntity: AvailableOfferEntity,
  searchParams: URLSearchParams
): boolean {
  if (searchParams.size === 0) {
    console.error('EB24: searchParam is empty');
    return true;
  }
  const checks: boolean[] = [];

  const filterReservedValue = getFilterValueFromAvailableOffer(isReserved, availableOfferEntity);

  if (filterReservedValue === null) {
    checks.push(true);
  } else {
    const isFilterMatch = isFilterWithMuplipleOptionsActive(
      searchParams,
      filterStateName,
      filterReservedValue
    );
    checks.push(isFilterMatch);
  }

  const filterAvailableValue = getFilterValueFromAvailableOffer(isAvailable, availableOfferEntity);
  if (filterAvailableValue === null) {
    checks.push(true);
  } else {
    const isFilterMatch = isFilterWithMuplipleOptionsActive(
      searchParams,
      filterStateName,
      filterAvailableValue
    );
    checks.push(isFilterMatch);
  }

  return checks.every((check) => check);
}

const isReserved = 'isReserved';
const isAvailable = 'isAvailable';

type AvailableFilterNames =
  | typeof gameFilterName
  | typeof serverFilterName
  | typeof filterIsDuoName
  | typeof filterTypeOfCoachName
  | typeof isReserved
  | typeof isAvailable;

export function getFilterValueFromAvailableOffer(
  filterName: AvailableFilterNames,
  availableOfferEntity: AvailableOfferEntity
): string | null {
  let value: string = '';

  switch (filterName) {
    case gameFilterName:
      value = availableOfferEntity.enquiry.game.value;
      break;
    case serverFilterName:
      if (availableOfferEntity.enquiry.serverLocation) {
        value = availableOfferEntity.enquiry.serverLocation.value;
      }
      break;
    case filterIsDuoName:
      value = boolToStringNumber(Boolean(availableOfferEntity.enquiry.isDuo));
      break;
    case filterTypeOfCoachName:
      if (availableOfferEntity.enquiry.typeOfCoach !== undefined) {
        value = availableOfferEntity.enquiry.typeOfCoach.value.toLowerCase();
      }
      break;
    case isReserved:
      if (availableOfferEntity.isReserved) {
        value = filterStateReserved.value;
      }
      break;
    case isAvailable:
      value = availableOfferEntity.isAvailable
        ? filterStateReadyToTake.value
        : filterStateNoAcess.value;
      break;
  }

  return value === '' ? null : value;
}

export function getAvailableOfferTitle(
  enquiry: AvailableOfferEnquiryResponseData,
  currentLabel?: string | null,
  desiredLabel?: string | null,
  wrapSeparator = false
): string {
  const game = enquiry.game.value;
  const {
    initialLeagueDivision,
    numberOfGames,
    numberOfPlacements,
    queueTypeEnum,
    currentLp,
    desiredLp,
    currentRr,
    desiredRr,
    platform,
    numberOfKills,
    numberOfWins,
    badge,
    challenge,
    numberOfTokens,
    typeOfCoach,
    numberOfHours
  } = enquiry;

  let title = '';

  const current = currentLabel === undefined ? getLabelCurrent(enquiry) : currentLabel;
  const desired = desiredLabel === undefined ? getLabelDesired(enquiry) : desiredLabel;

  switch (enquiry.serviceType.value) {
    case DriverServiceType.cs_bst_competitive:
      if (current && numberOfWins !== undefined) {
        title = current + ' - ' + numberOfWins + ' Wins';
      }
      break;
    case DriverServiceType.cmm_bst_placement:
      if (current && numberOfPlacements !== undefined) {
        title = current + ' - ' + numberOfPlacements + ' Games';
      }
      break;
    case DriverServiceType.cmm_bst_rank:
    case DriverServiceType.cs_bst_wingman:
    case DriverServiceType.lol_bst_unrankedpackage:
    case DriverServiceType.vrt_bst_unrankedpackage:
    case DriverServiceType.lol_bst_rankarena:
    case DriverServiceType.lol_bst_mastery:
      if (current && desired) {
        title = current + ' to ' + desired;
      }
      break;
    case DriverServiceType.cmm_bst_win:
      if (numberOfWins !== undefined) {
        const rankAndQueueTypeLabel = rankQueueTypeInitialLabel(
          initialLeagueDivision?.value,
          queueTypeEnum?.value,
          game
        );

        title = rankAndQueueTypeLabel + ' - ' + numberOfWins + ' Wins';
      }
      break;
    case DriverServiceType.cmm_bst_paypergames:
      if (numberOfGames !== undefined) {
        const rankAndQueueTypeLabel = rankQueueTypeInitialLabel(
          initialLeagueDivision?.value,
          queueTypeEnum?.value,
          game
        );

        title = rankAndQueueTypeLabel + ' - ' + numberOfGames + ' Games';
      }
      break;
    case DriverServiceType.lol_bst_clash:
      if (current && numberOfGames !== undefined) {
        title = 'Tier ' + current + ' - ' + numberOfGames + ' Games';
      }
      break;
    case DriverServiceType.lol_bst_winarena:
      if (current && numberOfGames !== undefined) {
        title = current + ' - ' + numberOfGames + ' Games';
      }
      break;
    case DriverServiceType.lol_bst_levelup:
      if (current && desired) {
        title = 'Level ' + current + ' to Level ' + desired;
      }
      break;
    case DriverServiceType.ap_bst_rank:
      if (current && desired) {
        title = current + ' RP to ' + desired + ' RP';
      }
      break;
    case DriverServiceType.cmm_bst_hightier:
      const start = isNumberAndNotNaN(currentLp)
        ? currentLp + ' LP'
        : currentRr !== undefined
          ? currentRr + ' RR'
          : '';
      const end =
        desiredLp !== undefined
          ? desiredLp + ' LP'
          : desiredRr !== undefined
            ? desiredRr + ' RR'
            : '';

      if (current && start && end) {
        title = current + ' - ' + start + ' to ' + end;
      }
      break;
    case DriverServiceType.ap_bst_kill:
      if (platform && numberOfKills) {
        title = platform.label + ' - ' + numberOfKills.label + ' Kills';
      }
      break;
    case DriverServiceType.cs_bst_premier:
      if (current && numberOfWins !== undefined) {
        title = 'Rank ' + current + ' - ' + numberOfWins + ' Wins';
      }
      break;
    case DriverServiceType.cs_bst_rank:
      if (current && desired) {
        title = 'Rank ' + current + ' to Rank ' + desired;
      }
      break;
    case DriverServiceType.ap_bst_badge:
      if (badge) {
        title = badge.label;
      }
      break;
    case DriverServiceType.lol_bst_challenge:
      if (challenge && numberOfTokens !== undefined) {
        title = challenge.label + ' - ' + numberOfTokens + ' Tokens';
      }
      break;
    case DriverServiceType.cmm_cch_default:
      if (typeOfCoach && numberOfHours !== undefined) {
        title = typeOfCoach.name + ' Coaching' + ' - ' + numberOfHours + ' hours';
      }
      break;
    default:
      title = 'Unknown service';
  }

  if (wrapSeparator && title) {
    title = title.replace(/( to | - )/gi, '<span class="thin">$1</span>');
  }

  return title;
}

export function hasServer(enquiry: AvailableOfferEnquiryResponseData): boolean {
  const gamesHasNoServer = [Game.apex, Game.rocket, Game.cs];

  return !gamesHasNoServer.includes(enquiry.game.value);
}

export function getLabelCurrent(enquiry: AvailableOfferEnquiryResponseData): string | null {
  let label: string | null = null;
  const serviceType = enquiry.serviceType.value;
  const game = enquiry.game.value;
  const { initialLeagueDivision, initialMasteryLevel, tier, initialLevel } = enquiry;

  switch (serviceType) {
    case DriverServiceType.cmm_bst_rank:
    case DriverServiceType.lol_bst_unrankedpackage:
    case DriverServiceType.vrt_bst_unrankedpackage:
    case DriverServiceType.lol_bst_winarena:
    case DriverServiceType.lol_bst_rankarena:
    case DriverServiceType.cs_bst_wingman:
    case DriverServiceType.cs_bst_competitive:
    case DriverServiceType.cmm_bst_placement:
      label = initialLeagueDivision
        ? getLeagueDivisionLabel(game, initialLeagueDivision.value)
        : null;
      break;
    case DriverServiceType.cmm_bst_win:
    case DriverServiceType.cmm_bst_paypergames:
    case DriverServiceType.cmm_bst_hightier:
      label = initialLeagueDivision
        ? getLeagueDivisionLabel(game, initialLeagueDivision.value)
        : 'Any ranked';
      break;
    case DriverServiceType.lol_bst_mastery:
      label = initialMasteryLevel ? getMasteryLevelLabel(initialMasteryLevel.value) : null;
      break;
    case DriverServiceType.lol_bst_clash:
      label = tier ? tier.label : null;
      break;
    case DriverServiceType.cs_bst_rank:
    case DriverServiceType.cs_bst_premier:
    case DriverServiceType.lol_bst_levelup:
    case DriverServiceType.ap_bst_rank:
      label = initialLevel !== undefined ? String(initialLevel) : null;
      break;
  }

  return label;
}

export function getLabelDesired(enquiry: AvailableOfferEnquiryResponseData): string | null {
  let label: string | null = null;
  const serviceType = enquiry.serviceType.value;
  const game = enquiry.game.value;
  const { desiredLeagueDivision, desiredMasteryLevel, desiredLevel } = enquiry;

  switch (serviceType) {
    case DriverServiceType.cmm_bst_rank:
    case DriverServiceType.lol_bst_unrankedpackage:
    case DriverServiceType.vrt_bst_unrankedpackage:
    case DriverServiceType.cs_bst_wingman:
    case DriverServiceType.lol_bst_rankarena:
      label = desiredLeagueDivision
        ? getLeagueDivisionLabel(game, desiredLeagueDivision.value)
        : null;
      break;
    case DriverServiceType.lol_bst_mastery:
      label = desiredMasteryLevel ? getMasteryLevelLabel(desiredMasteryLevel.value) : null;
      break;
    case DriverServiceType.cs_bst_rank:
    case DriverServiceType.lol_bst_levelup:
    case DriverServiceType.ap_bst_rank:
      label = desiredLevel !== undefined ? String(desiredLevel) : null;
      break;
  }

  return label;
}
