import { BaseEntity } from '@svelte/lib/entity/base.entity';

export interface UserMarketplaceProfileResponseData {
  id: number;
  verified_seller: boolean;
  is_eb24_seller: boolean;
}

export interface UserMarketplaceProfileData {
  id: number;
  verified_seller: boolean;
  is_eb24_seller: boolean;
}

export class UserMarketplaceProfileEntity extends BaseEntity<UserMarketplaceProfileData> {
  get id() {
    return this.data.id;
  }
  get verified_seller() {
    return this.data.verified_seller;
  }
  get isEb24Seller() {
    return this.data.is_eb24_seller;
  }
  get sellerName() {
    switch (true) {
      case this.isEb24Seller:
        return 'EB24 Seller';
      case this.verified_seller:
        return 'Top Seller';
      default:
        return 'Regular';
    }
  }
}
