import { type FilterDetail } from '@svelte/lib/interface/dashboard/common';
import {
  getLolServerLabel,
  getTftServerLabel,
  getValorantServerLabel,
  getWildRiftServerLabel,
  getUserLocationLabel,
  LolServers,
  TftServers,
  UserLocationEnum,
  ValorantServers,
  WildRiftServers,
  OverwatchServers,
  getOwServerLabel
} from '@svelte/lib/enum/drivers/servers';
import { CommonFilterInputType, UniqueFiltersStorageName } from '@svelte/lib/enum/dashboard/common';
import { Game, getGameFullName } from '@svelte/lib/enum/drivers/base';
import { SortingAvailableOffers } from '@svelte/lib/enum/dashboard/available-offers';
import type { SelectOption } from '@svelte/lib/interface/types';
import {
  getSearchParamsFromStorage,
  saveToStorage
} from '@svelte/lib/helpers/dashboard/commonSortingAndFilters';
import gameIcon from '@images/refactor/svg/ui/eb24/mouse.svg?raw';
import stateIcon from '@images/refactor/svg/ui/eb24/order_status.svg?raw';
import layoutIcon from '@images/refactor/svg/ui/eb24/layout.svg?raw';
import serverIcon from '@images/refactor/svg/ui/eb24/server.svg?raw';
import twoGuysIcon from '@images/refactor/svg/ui/eb24/two_guys.svg?raw';

export const defaultPerPage = '10';

export const BOOSTING_UNIQUE_STORAGE_NAME: UniqueFiltersStorageName =
  UniqueFiltersStorageName.boosting;
export const COACHING_UNIQUE_STORAGE_NAME: UniqueFiltersStorageName =
  UniqueFiltersStorageName.coaching;

export const defaultBoostingSearchObj = {
  page: '1',
  per_page: defaultPerPage,
  sort: SortingAvailableOffers.newest,
  layout: 'compact'
} as const;

export function getBoostingSearchParams(): URLSearchParams {
  return getSearchParamsFromStorage(BOOSTING_UNIQUE_STORAGE_NAME, defaultBoostingSearchObj);
}

export function saveBoostingSearchParams(searchParams: URLSearchParams): void {
  saveToStorage(BOOSTING_UNIQUE_STORAGE_NAME, searchParams);
}

export const defaultCoachingSearchObj = {
  page: '1',
  per_page: defaultPerPage,
  sort: SortingAvailableOffers.newest,
  layout: 'compact'
} as const;

export function getCoachingSearchParams(): URLSearchParams {
  return getSearchParamsFromStorage(COACHING_UNIQUE_STORAGE_NAME, defaultBoostingSearchObj);
}

export function saveCoachingSearchParams(searchParams: URLSearchParams): void {
  saveToStorage(COACHING_UNIQUE_STORAGE_NAME, searchParams);
}

export const gameFilterName = 'filter[game]';
export const serverFilterName = 'filter[server]';
export const filterIsDuoName = 'filter[isDuo]';
export const filterTypeOfCoachName = 'filter[typeOfCoach]';
export const filterStateName = 'state';

export const gameFilters = Object.values(Game).map((game) => ({
  label: getGameFullName(game),
  name: gameFilterName,
  value: game,
  checked: false,
  type: CommonFilterInputType.button,
  category: 'game'
}));

export const lolServerFilters = Object.values(LolServers).map((server) => ({
  label: getLolServerLabel(server),
  name: serverFilterName,
  value: server,
  checked: false,
  type: CommonFilterInputType.checkbox,
  category: 'lolServer',
  isVisible: (searchParam: URLSearchParams): boolean => {
    return isServerFilterVisible(searchParam, Game.leagueoflegends);
  }
}));

export const tftServerFilters = Object.values(TftServers).map((server) => ({
  label: getTftServerLabel(server),
  name: serverFilterName,
  value: server,
  checked: false,
  type: CommonFilterInputType.checkbox,
  category: 'tftServer',
  isVisible: (searchParam: URLSearchParams): boolean => {
    return isServerFilterVisible(searchParam, Game.teamfight_tactics);
  }
}));

export const valorantServerFilters = Object.values(ValorantServers).map((server) => ({
  label: getValorantServerLabel(server),
  name: serverFilterName,
  value: server,
  checked: false,
  type: CommonFilterInputType.checkbox,
  category: 'vrtServer',
  isVisible: (searchParam: URLSearchParams): boolean => {
    return isServerFilterVisible(searchParam, Game.valorant);
  }
}));

function isServerFilterVisible(searchParam: URLSearchParams, game: Game): boolean {
  const isGameFilter = searchParam.has('game');
  const valueGame = searchParam.get('game');

  if (!isGameFilter || !valueGame) {
    return true;
  }

  return decodeURI(valueGame).split(',').includes(game);
}

export const widRiftServerFilters = Object.values(WildRiftServers).map((server) => ({
  label: getWildRiftServerLabel(server),
  name: serverFilterName,
  value: server,
  checked: false,
  type: CommonFilterInputType.checkbox,
  category: 'wildriftServer',
  isVisible: (searchParam: URLSearchParams): boolean => {
    return isServerFilterVisible(searchParam, Game.wildrift);
  }
}));

export const overwatchServerFilters = Object.values(OverwatchServers).map((server) => ({
  label: getOwServerLabel(server),
  name: serverFilterName,
  value: server,
  checked: false,
  type: CommonFilterInputType.checkbox,
  category: 'overwatchServer',
  isVisible: (searchParam: URLSearchParams): boolean => {
    return isServerFilterVisible(searchParam, Game.overwatch);
  }
}));

export const apexCsRocketServerFilters = Object.values(UserLocationEnum).map((server) => ({
  label: getUserLocationLabel(server),
  name: serverFilterName,
  value: server,
  checked: false,
  type: CommonFilterInputType.checkbox,
  category: 'apexCsRocketServer',
  isVisible: (searchParam: URLSearchParams): boolean => {
    return (
      isServerFilterVisible(searchParam, Game.apex) ||
      isServerFilterVisible(searchParam, Game.cs) ||
      isServerFilterVisible(searchParam, Game.rocket)
    );
  }
}));

export const getCategoryLabel = (category: string): string => {
  switch (category) {
    case 'game':
      return 'Show games';
    case 'lolServer':
      return 'League of Legends server';
    case 'tftServer':
      return 'Teamfight Tactics server';
    case 'vrtServer':
      return 'Valorant server';
    case 'wildriftServer':
      return 'Wild Rift server';
    case 'overwatchServer':
      return 'Overwatch server';
    case 'apexCsRocketServer':
      return 'Apex, CS2, Rocket server';
    case 'isDuo':
    case 'coachType':
      return 'Order type';
    case 'state':
      return 'Offer state';
    case 'layout':
      return 'Offers default layout';
    default:
      return '';
  }
};

export const getCategorySvgInlineIcon = (category: string): string => {
  switch (category) {
    case 'game':
      return gameIcon;
    case 'layout':
      return layoutIcon;
    case 'lolServer':
    case 'tftServer':
    case 'vrtServer':
    case 'wildriftServer':
    case 'overwatchServer':
    case 'apexCsRocketServer':
      return serverIcon;
    case 'isDuo':
    case 'coachType':
      return twoGuysIcon;
    case 'state':
      return stateIcon;
    default:
      return '';
  }
};

export const sortinCategoryName = 'sorting_section';
export const sortingOptions: SelectOption[] = [
  {
    value: SortingAvailableOffers.newest,
    label: 'Newest'
  },
  {
    value: SortingAvailableOffers.oldest,
    label: 'Oldest'
  },
  {
    value: SortingAvailableOffers.priceHighToLow,
    label: 'Price: High to Low'
  },
  {
    value: SortingAvailableOffers.priceLowToHigh,
    label: 'Price: Low to High'
  }
];

export const sortFilter: FilterDetail = {
  label: 'Sort by',
  name: 'sort',
  options: sortingOptions,
  value: SortingAvailableOffers.newest,
  checked: true,
  type: CommonFilterInputType.select,
  category: sortinCategoryName
};

export const itemsPerPageOptions: SelectOption[] = [
  {
    value: '10',
    label: '10'
  },
  {
    value: '20',
    label: '20'
  },
  {
    value: '30',
    label: '30'
  }
];

export const pageFilter: FilterDetail = {
  label: 'Page',
  name: 'page',
  value: '1',
  checked: true,
  type: CommonFilterInputType.pagination,
  options: [{ label: '1', value: '1' }],
  category: sortinCategoryName
};

export const itemsPerPageFilter: FilterDetail = {
  label: 'Items per page',
  name: 'per_page',
  options: itemsPerPageOptions,
  value: defaultPerPage,
  checked: true,
  type: CommonFilterInputType.select,
  category: sortinCategoryName
};

export const priceFromFilter: FilterDetail = {
  label: 'From',
  name: 'filter[price_from]',
  value: undefined,
  checked: false,
  type: CommonFilterInputType.inputNumber,
  category: 'price'
};

export const priceToFilter: FilterDetail = {
  label: 'To',
  name: 'filter[price_to]',
  value: undefined,
  checked: false,
  type: CommonFilterInputType.inputNumber,
  category: 'price'
};

export const filterStateReadyToTake = {
  label: 'Ready to take',
  name: filterStateName,
  value: '0',
  checked: false,
  type: CommonFilterInputType.checkbox,
  category: 'state'
};

export const filterStateNoAcess = {
  label: 'No access',
  name: filterStateName,
  value: '1',
  checked: false,
  type: CommonFilterInputType.checkbox,
  category: 'state'
};

export const filterStateReserved = {
  label: 'Reserved',
  name: filterStateName,
  value: '2',
  checked: false,
  type: CommonFilterInputType.checkbox,
  category: 'state'
};

export const isFilterStateActive = (searchParam: URLSearchParams, value: string): boolean => {
  const filterValue = searchParam.get(filterStateName);
  // the same as all options are selected
  if (filterValue === null) {
    return true;
  }

  return filterValue.split(',').includes(value);
};

export const isFilterStateReadyToTakeActive = (searchParam: URLSearchParams): boolean => {
  return isFilterStateActive(searchParam, filterStateReadyToTake.value);
};

export const isFilterStateNoAccessActive = (searchParam: URLSearchParams): boolean => {
  return isFilterStateActive(searchParam, filterStateNoAcess.value);
};

export const isFilterStateReservedActive = (searchParam: URLSearchParams): boolean => {
  return isFilterStateActive(searchParam, filterStateReserved.value);
};
