export const isDriverServiceType = (serviceType: string): serviceType is DriverServiceType => {
  return Object.values(DriverServiceType).includes(serviceType as DriverServiceType);
};

export enum DriverServiceType {
  cmm_bst_rank = 'cmm_bst_rank',
  cmm_bst_placement = 'cmm_bst_placement',
  cmm_bst_win = 'cmm_bst_win',
  cmm_bst_paypergames = 'cmm_bst_paypergames',
  cmm_cch_default = 'cmm_cch_default',
  lol_bst_clash = 'lol_bst_clash',
  lol_bst_mastery = 'lol_bst_mastery',
  lol_bst_levelup = 'lol_bst_levelup',
  cmm_bst_hightier = 'cmm_bst_hightier',
  lol_bst_winarena = 'lol_bst_winarena',
  lol_bst_rankarena = 'lol_bst_rankarena',
  lol_bst_challenge = 'lol_bst_challenge',
  lol_bst_unrankedpackage = 'lol_bst_unrankedpackage',
  ap_bst_rank = 'ap_bst_rank',
  ap_bst_kill = 'ap_bst_kill',
  ap_bst_badge = 'ap_bst_badge',
  cs_bst_rank = 'cs_bst_rank',
  cs_bst_wingman = 'cs_bst_wingman',
  cs_bst_premier = 'cs_bst_premier',
  cs_bst_competitive = 'cs_bst_competitive',
  vrt_bst_unrankedpackage = 'vrt_bst_unrankedpackage'
}

export const getDriverServiceTypeLabel = (serviceType: string): string => {
  if (!isDriverServiceType(serviceType)) {
    console.log('Unknown service type:', serviceType);
    return '';
  }

  switch (serviceType) {
    case DriverServiceType.cmm_bst_rank:
    case DriverServiceType.ap_bst_rank:
      return 'division boosting';
    case DriverServiceType.cmm_bst_placement:
      return 'placement matches';
    case DriverServiceType.cmm_bst_win:
      return 'win boosting';
    case DriverServiceType.cmm_bst_paypergames:
      return 'pay per games';
    case DriverServiceType.cmm_cch_default:
      return 'coaching';
    case DriverServiceType.lol_bst_clash:
      return 'clash boosting';
    case DriverServiceType.lol_bst_mastery:
      return 'champion mastery';
    case DriverServiceType.lol_bst_levelup:
      return 'level up boosting';
    case DriverServiceType.cmm_bst_hightier:
      return 'high tier boosting';
    case DriverServiceType.lol_bst_winarena:
      return 'arena win boosting';
    case DriverServiceType.lol_bst_rankarena:
      return 'arena rank boosting';
    case DriverServiceType.lol_bst_challenge:
      return 'challenge boosting';
    case DriverServiceType.lol_bst_unrankedpackage:
    case DriverServiceType.vrt_bst_unrankedpackage:
      return 'unranked package boosting';
    case DriverServiceType.ap_bst_kill:
      return 'kill boosting';
    case DriverServiceType.ap_bst_badge:
      return 'badge boosting';
    case DriverServiceType.cs_bst_rank:
      return 'premier rank boosting';
    case DriverServiceType.cs_bst_wingman:
      return 'wingman boosting';
    case DriverServiceType.cs_bst_premier:
      return 'premier wins';
    case DriverServiceType.cs_bst_competitive:
      return 'competitive wins';
    default:
      return '';
  }
};

export const isGame = (game: string): game is Game => {
  return Object.values(Game).includes(game as Game);
};

export enum Game {
  leagueoflegends = 'lol',
  teamfight_tactics = 'tft',
  valorant = 'vrt',
  overwatch = 'ow',
  apex = 'ap',
  rocket = 'rl',
  cs = 'cs',
  wildrift = 'wr'
}

export const getGameFullName = (game: string): string => {
  if (!isGame(game)) {
    console.log('Unknown game:', game);
    return '';
  }

  switch (game) {
    case Game.leagueoflegends:
      return 'League of Legends';
    case Game.teamfight_tactics:
      return 'Teamfight Tactics';
    case Game.valorant:
      return 'Valorant';
    case Game.overwatch:
      return 'Overwatch';
    case Game.apex:
      return 'Apex';
    case Game.rocket:
      return 'Rocket';
    case Game.cs:
      return 'Counter Strike';
    case Game.wildrift:
      return 'Wild Rift';
    default:
      return '';
  }
};

export const getGameShortName = (game: string): string => {
  if (!isGame(game)) {
    console.log('Unknown game:', game);
    return '';
  }

  switch (game) {
    case Game.leagueoflegends:
      return 'LOL';
    case Game.teamfight_tactics:
      return 'TFT';
    case Game.valorant:
      return 'VRT';
    case Game.overwatch:
      return 'Overwatch';
    case Game.apex:
      return 'Apex';
    case Game.rocket:
      return 'Rocket';
    case Game.cs:
      return 'CS2';
    case Game.wildrift:
      return 'Wild Rift';
    default:
      return '';
  }
};
