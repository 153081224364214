import { CommonFilterInputType, UniqueFiltersStorageName } from '@svelte/lib/enum/dashboard/common';
import type { FilterDetail } from '@svelte/lib/interface/dashboard/common';

export function bindSearchParams(filtersArr: FilterDetail[], searchParams: URLSearchParams) {
  filtersArr.forEach((filter) => {
    if (
      filter.type === CommonFilterInputType.checkbox ||
      filter.type === CommonFilterInputType.button
    ) {
      filter.checked = searchParams.get(filter.name)?.split(',')?.includes(String(filter.value));
    }

    if (filter.type === CommonFilterInputType.inputNumber) {
      filter.value = searchParams.get(filter.name) ?? filter.value;
    }

    if (
      (filter.type === CommonFilterInputType.buttonRadio ||
        filter.type === CommonFilterInputType.select) &&
      filter.options
    ) {
      const selectedOption = filter.options.find(
        (option) => searchParams.get(filter.name) === option.value
      );

      if (selectedOption) {
        filter.value = selectedOption.value;
      }
    }
  });
}

export function getSortingAndFiltersSearchParams(
  filtersArr: FilterDetail[],
  commonSeachParams: URLSearchParams,
  sortingCategoryName: string
): { filtersSearchParams: URLSearchParams; sortingSearchParams: URLSearchParams } {
  type SplitedSearchObj = {
    filters: { [key: FilterDetail['name']]: string };
    sorting: { [key: FilterDetail['name']]: string };
  };

  const sortingFiltersObj: SplitedSearchObj = { filters: {}, sorting: {} };

  filtersArr.forEach((filter) => {
    const valueFilter = commonSeachParams.get(filter.name);

    if (valueFilter === null) {
      return;
    }

    if (filter.category === sortingCategoryName) {
      sortingFiltersObj.sorting[filter.name] = valueFilter;
    } else {
      sortingFiltersObj.filters[filter.name] = valueFilter;
    }
  });

  return {
    filtersSearchParams: new URLSearchParams(sortingFiltersObj.filters),
    sortingSearchParams: new URLSearchParams(sortingFiltersObj.sorting)
  };
}

const storagePostfix = '_last_user_search_params';

export function saveToStorage(
  uniqueStorageName: UniqueFiltersStorageName,
  searchParams: URLSearchParams
) {
  window.localStorage.setItem(`${uniqueStorageName}${storagePostfix}`, searchParams.toString());
}

export function getSearchParamsFromStorage(
  uniqueStorageName: UniqueFiltersStorageName,
  searchObj: { [key: string]: string } = {}
): URLSearchParams {
  if (import.meta.env.SSR) {
    return new URLSearchParams(searchObj);
  }

  const storageData = window.localStorage.getItem(`${uniqueStorageName}${storagePostfix}`);
  const params = new URLSearchParams(storageData || searchObj);

  return params;
}

export function getSelectedFilters(filtersArr: FilterDetail[], searchParams: URLSearchParams) {
  const selectedFilters: { [key: FilterDetail['name']]: string } = {};

  filtersArr.forEach((filter) => {
    if (typeof filter.isVisible === 'function' && !filter.isVisible(searchParams)) {
      return;
    }

    const isCheckboxOrButton = [
      CommonFilterInputType.checkbox,
      CommonFilterInputType.button
    ].includes(filter.type);

    if (isCheckboxOrButton && !filter.checked) {
      return;
    }

    const isButtonRadioOrSelectOrInputNumber = [
      CommonFilterInputType.buttonRadio,
      CommonFilterInputType.select,
      CommonFilterInputType.inputNumber
    ].includes(filter.type);

    if (isButtonRadioOrSelectOrInputNumber && !filter.value) {
      return;
    }

    const filterValue = String(filter.value);

    if (filter.name in selectedFilters) {
      selectedFilters[filter.name] += `,${filterValue}`;
    } else {
      selectedFilters[filter.name] = filterValue;
    }
  });

  return selectedFilters;
}

export function getFiltersGroupByCategory(
  filtersArr: FilterDetail[],
  searchParams: URLSearchParams = new URLSearchParams({})
) {
  const filtersGroupByCategory: { [key: FilterDetail['category']]: FilterDetail[] } = {};

  filtersArr.forEach((filter) => {
    if (typeof filter.isVisible === 'function' && !filter.isVisible(searchParams)) {
      return;
    }

    if (filter.category in filtersGroupByCategory) {
      filtersGroupByCategory[filter.category].push(filter);
    } else {
      filtersGroupByCategory[filter.category] = [filter];
    }
  });

  return filtersGroupByCategory;
}

export function isFilterActive(searchParams: URLSearchParams, filter: FilterDetail) {
  const value = searchParams.get(filter.name);
  if (value) {
    return value.split(',').includes(String(filter.value));
  }

  return false;
}

export const isFilterWithMuplipleOptionsActive = (
  searchParams: URLSearchParams,
  filterName: string,
  value: string
): boolean => {
  const filterValue = searchParams.get(filterName);
  // the same as all options are selected
  if (filterValue === null) {
    return true;
  }

  return filterValue.split(',').includes(value);
};

export function getSearchParamsExcludeKeys(params: URLSearchParams, excludeKeys: string[]) {
  const paramsCopy = new URLSearchParams(params);
  excludeKeys.forEach((key) => {
    paramsCopy.delete(key);
  });

  return paramsCopy;
}
