export type Auth = User | null;

export enum UserRole {
  User = 0,
  Booster = 1,
  Admin = 2
}

export type DiscordName = string | false;

export interface User {
  id: number;
  name: string;
  verified_seller: boolean;
  role: UserRole;
  avatar: string;
  discordName: DiscordName;
}

export type OriginUser = string | null;

export interface TwoFactorSetupResponse {
  image: string;
  secretKey: string;
}
