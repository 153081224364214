import { BoostStatus, getBoostStatusLabel } from '@svelte/lib/enum/dashboard/boost-status';
import { DriverServiceType, Game } from '@svelte/lib/enum/drivers/base';
import { Currency, type CurrencySymbol } from '@svelte/lib/enum/common';
import { BaseEntity } from '@svelte/lib/entity/base.entity';
import { CoachingStatus, getCoachingStatusLabel } from '@svelte/lib/enum/dashboard/coaching-status';
import type { PaginationItem } from '@svelte/lib/interface/pagination';
import type { UserEntity, UserResponseData } from '@svelte/lib/entity/user.entity';

export interface AvailableOfferResponse {
  available: {
    data: AvailableOfferResponseData[];
    meta: {
      current_page: number;
      max_page: number;
      links: PaginationItem[];
      total: number;
    };
  };
}

interface EnquiryItemObjectSimple {
  name: string;
  value: string;
}
interface EnquiryItemObject extends EnquiryItemObjectSimple {
  label: string;
}

interface GameEnquiryItem extends EnquiryItemObject {
  value: Game;
}

interface ServiceTypeEnquiryItem extends EnquiryItemObjectSimple {
  value: DriverServiceType;
}

export interface AvailableOfferEnquiryResponseData {
  game: GameEnquiryItem;
  serviceType: ServiceTypeEnquiryItem;
  vip?: boolean;
  isDuo?: boolean;
  champions?: EnquiryItemObject[];
  rolesSolo?: EnquiryItemObject[];
  duoPremium?: boolean;
  roleSelected?: boolean;
  appearOffline?: boolean;
  gameStreaming?: boolean;
  numberOfGames?: number;
  numberOfWins?: number;
  numberOfPlacements?: number;
  numberOfHours?: number;
  numberOfKills?: EnquiryItemObject;
  numberOfTokens?: number;
  priorityQueue?: boolean;
  summonerSpell?: EnquiryItemObject;
  primaryRoleDuo?: EnquiryItemObject;
  primaryRole?: EnquiryItemObject;
  secondaryRole?: EnquiryItemObject;
  serverLocation?: EnquiryItemObject;
  secondaryRoleDuo?: EnquiryItemObject;
  championsSelected?: boolean;
  initialLeagueDivision?: EnquiryItemObjectSimple;
  desiredLeagueDivision?: EnquiryItemObjectSimple;
  summonerSpellSelected?: boolean;
  initialMasteryLevel?: EnquiryItemObject;
  desiredMasteryLevel?: EnquiryItemObject;
  initialLevel?: number;
  desiredLevel?: number;
  queueTypeEnum?: EnquiryItemObject | EnquiryItemObjectSimple;
  leaguePoints?: EnquiryItemObject;
  shareScreen?: boolean;
  currentLp?: number | EnquiryItemObject | null;
  desiredLp?: number;
  currentRr?: number;
  desiredRr?: number;
  challenge?: EnquiryItemObject;
  badge?: EnquiryItemObject;
  platform?: EnquiryItemObject;
  tier?: EnquiryItemObject;
  map?: EnquiryItemObject;
  typeOfCoach?: EnquiryItemObjectSimple;
  marks?: EnquiryItemObject;
  brackets?: EnquiryItemObject;
  rankRating?: EnquiryItemObject;
  leaguePointsGain?: EnquiryItemObject;
  characters?: EnquiryItemObject[];
  extendedQueueTypeEnum?: EnquiryItemObject | EnquiryItemObjectSimple;
  soloAce?: boolean;
  ghostDuo?: boolean;
}

export interface OfferAvailablityResponse {
  available: boolean;
  available_error: string;
  available_expire_at: string | null;
}

export interface AvailableOfferResponseData extends OfferAvailablityResponse {
  id: number;
  price: number;
  booster_id: number | null;
  status: BoostStatus | CoachingStatus;
  created_at: string;
  updated_at: string;
  enquiry: AvailableOfferEnquiryResponseData;
  user: UserResponseData;
  currency: Currency;
  percentage: string;
  description: string | null;
  buyer_country?: string;
  reviewHash?: string;
  orderHash?: string;
}

export interface AvailableOfferData {
  id: number;
  isCoaching: boolean;
  price: string;
  boosterId: number | null;
  status: BoostStatus | CoachingStatus;
  isReserved: boolean;
  createdAt: string;
  // updated_at: string; // TODO: remove if not needed and remode at data recived from backend
  enquiry: AvailableOfferEnquiryResponseData;
  user: UserEntity;
  currency: Currency;
  currencySymbol: CurrencySymbol;
  percentage: string;
  priceContractor: string;
  rate: string;
  description: string | null;
  available: boolean;
  availableError?: string;
  availableExpireAt: string | null;
  title: string;
  titleDuo?: string;
  duoType?: string;
  gameLabel: string;
  serviceTypeLabel: string;
  shouldHasServer: boolean;
  serverLabel: string;
  isPriority?: boolean;
  typeOfCoach?: string;
  currentLabel: string | null;
  desiredLabel: string | null;
  buyerCountry?: string;
  reviewHash?: string;
  orderHash?: string;
}

export class AvailableOfferEntity extends BaseEntity<AvailableOfferData> {
  constructor(data: AvailableOfferData) {
    super(data);
  }

  get id(): number {
    return this.data.id;
  }

  get isCoaching(): boolean {
    return this.data.isCoaching;
  }

  get status(): BoostStatus | CoachingStatus {
    return this.data.status;
  }

  get statusLabel(): string {
    return this.data.isCoaching
      ? getCoachingStatusLabel(this.data.status)
      : getBoostStatusLabel(this.data.status);
  }

  get title(): string {
    return this.data.title;
  }

  get titleDuo(): string | undefined {
    return this.data.titleDuo;
  }

  get duoType(): string | undefined {
    return this.data.duoType;
  }

  get subtitle(): string {
    return this.data.isCoaching ? this.gameLabel : this.serviceTypeLabel;
  }

  get gameLabel(): string {
    return this.data.gameLabel;
  }

  get serviceTypeLabel(): string {
    return this.data.serviceTypeLabel;
  }

  get shouldHasServer(): boolean {
    return this.data.shouldHasServer;
  }

  get serverLabel(): string {
    return this.data.serverLabel;
  }

  get price(): string {
    return this.data.price;
  }

  get currency(): Currency {
    return this.data.currency;
  }

  get currencySymbol(): CurrencySymbol {
    return this.data.currencySymbol;
  }

  get percentage(): string {
    return this.data.percentage;
  }

  get priceContractor(): string {
    return this.data.priceContractor;
  }

  get rate(): string {
    return this.data.rate;
  }

  get description(): string | null {
    return this.data.description;
  }

  get isReserved(): boolean {
    return this.data.isReserved;
  }

  get isAvailable(): boolean {
    return this.data.available;
  }

  get availableError(): string | undefined {
    return this.data.availableError;
  }

  get availableExpireAt(): string | null {
    return this.data.availableExpireAt;
  }

  get createdAt(): string {
    return this.data.createdAt;
  }

  get enquiry(): AvailableOfferEnquiryResponseData {
    return this.data.enquiry;
  }

  get user(): UserEntity {
    return this.data.user;
  }

  get isPriority(): boolean | undefined {
    return this.data.isPriority;
  }

  get typeOfCoach(): string | undefined {
    return this.data.typeOfCoach;
  }

  get currentLabel(): string | null {
    return this.data.currentLabel;
  }

  get desiredLabel(): string | null {
    return this.data.desiredLabel;
  }

  get buyerCountry(): string | undefined {
    return this.data.buyerCountry;
  }

  get boosterId(): number | null {
    return this.data.boosterId;
  }

  get reviewHash(): string | undefined {
    return this.data.reviewHash;
  }

  get orderHash(): string | undefined {
    return this.data.orderHash;
  }
}
