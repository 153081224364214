export enum BoostStatus {
  WaitingForBooster = 0, // booster not assigned, order created, paid
  InProgress = 1, // service in progress
  Paused = 2, // service paused by the customer
  Ended = 3, // completed
  Abandoned = 4, // abandoned by booster, alternatively a customer requested new booster, waiting for admin action
  ReservedForBooster = 5, // reserved for the chosen booster, not available for the rest
  Archived = 6,
  WaitingForDetails = 7, // order created, waiting for customer to provide details
  WaitingForPayment = 8, // order created, waiting for customer to pay
  Canceled = 9 // order canceled, payment has been reverted
}

export const isBoostStatus = (status: number): status is BoostStatus => {
  return Object.values(BoostStatus).includes(status as BoostStatus);
};

export const getBoostStatusLabel = (status: number): string => {
  if (!isBoostStatus(status)) {
    console.log('Unknown boost status:', status);
    return 'Uknown boost status';
  }

  switch (status) {
    case BoostStatus.WaitingForBooster:
      return 'Waiting for booster';
    case BoostStatus.InProgress:
      return 'In progress';
    case BoostStatus.Paused:
      return 'Paused';
    case BoostStatus.Ended:
      return 'Ended';
    case BoostStatus.Abandoned:
      return 'Abandoned';
    case BoostStatus.ReservedForBooster:
      return 'Reserved for booster';
    case BoostStatus.Archived:
      return 'Archived';
    case BoostStatus.WaitingForDetails:
      return 'Waiting for details';
    case BoostStatus.WaitingForPayment:
      return 'Waiting for payment';
    case BoostStatus.Canceled:
      return 'Canceled';
    default:
      console.log('Unknown boost status:', status);
      return 'Unknown status';
  }
};
