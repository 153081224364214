import type { AttributesObject } from '@svelte/lib/mapper/boosting/preparedData';
import type { SelectOption } from '@svelte/lib/interface/types';
import { Game } from '@svelte/lib/enum/drivers/base';
import type {
  DesiredLeagueDivisionSettings,
  InitialLeagueDivisionSettings,
  LeagueDivisionSettings
} from '@svelte/lib/entity/drivers/common.entity';

function initialAndDesiredExist(settingsEntity: LeagueDivisionSettings): boolean {
  const initialExists = settingsEntity.initialLeagueDivision !== undefined;
  const desiredExists = settingsEntity.desiredLeagueDivision !== undefined;
  return initialExists && desiredExists;
}

export function updateDesiredValueRelatedToInitial(settingsEntity: LeagueDivisionSettings) {
  if (!initialAndDesiredExist(settingsEntity)) {
    return;
  }

  if (!Array.isArray(settingsEntity.desiredLeagueDivision.choices)) {
    return;
  }

  const initialIndex = settingsEntity.desiredLeagueDivision.choices.findIndex(
    (opt) => opt.value === settingsEntity.initialLeagueDivision.currentValue
  );

  if (initialIndex === -1) {
    return;
  }

  const desiredIndex = settingsEntity.desiredLeagueDivision.choices.findIndex(
    (opt) => opt.value === settingsEntity.desiredLeagueDivision.currentValue
  );

  const isInitialSmaller = initialIndex < desiredIndex;
  if (isInitialSmaller) {
    return;
  }

  const lastIndex = settingsEntity.desiredLeagueDivision.choices.length - 1;
  const isUpdateImposible = initialIndex + 1 > lastIndex;
  if (isUpdateImposible) {
    return;
  }

  const correctDesired = String(
    settingsEntity.desiredLeagueDivision.choices[initialIndex + 1].value
  );

  settingsEntity.desiredLeagueDivision.currentValue = correctDesired;
}

export function updateInitialValueRelatedToDesired(settingsEntity: LeagueDivisionSettings) {
  if (!initialAndDesiredExist(settingsEntity)) {
    return;
  }

  if (!Array.isArray(settingsEntity.initialLeagueDivision.choices)) {
    return;
  }

  const desiredIndex = settingsEntity.initialLeagueDivision.choices.findIndex((opt) => {
    return opt.value === settingsEntity.desiredLeagueDivision.currentValue;
  });
  if (desiredIndex === -1) {
    return;
  }

  const initialIndex = settingsEntity.initialLeagueDivision.choices.findIndex((opt) => {
    return opt.value === settingsEntity.initialLeagueDivision.currentValue;
  });

  const isInitialSmaller = initialIndex < desiredIndex;
  if (isInitialSmaller) {
    return;
  }

  const isUpdateImposible = desiredIndex - 1 < 0;
  if (isUpdateImposible) {
    return;
  }

  const correctInitial = String(
    settingsEntity.initialLeagueDivision.choices[desiredIndex - 1].value
  );

  settingsEntity.initialLeagueDivision.currentValue = correctInitial;
}

export function getLeagueDivisionLabel(game: Game, leagueDivision: string): string {
  switch (game) {
    case Game.leagueoflegends:
      return lolLeagueDivisionLabel(leagueDivision);
    case Game.valorant:
      return vrtLeagueDivisionLabel(leagueDivision);
    case Game.teamfight_tactics:
      return tftLeagueDivisionLabel(leagueDivision);
    case Game.overwatch:
      return overwatchLeagueDivisionLabel(leagueDivision);
    case Game.apex:
      return apexLeagueDivisionLabel(leagueDivision);
    case Game.rocket:
      return rocketLeagueDivisionLabel(leagueDivision);
    case Game.cs:
      return csLeagueDivisionLabel(leagueDivision);
    case Game.wildrift:
      return wildriftLeagueDivisionLabel(leagueDivision);
    default:
      console.error(`Unknown game: ${game}`);
      return leagueDivision;
  }
}

export function lolLeagueDivisionLabel(leagueDivision: string): string {
  switch (leagueDivision) {
    case 'lol_ldv_wood':
      return 'Wood';
    case 'lol_ldv_gladiator':
      return 'Gladiator';
    case 'lol_ldv_unranked':
      return 'Unranked';
    case 'lol_ldv_iron-4':
      return 'Iron IV';
    case 'lol_ldv_iron-3':
      return 'Iron III';
    case 'lol_ldv_iron-2':
      return 'Iron II';
    case 'lol_ldv_iron-1':
      return 'Iron I';
    case 'lol_ldv_iron':
      return 'Iron';
    case 'lol_ldv_bronze-4':
      return 'Bronze IV';
    case 'lol_ldv_bronze-3':
      return 'Bronze III';
    case 'lol_ldv_bronze-2':
      return 'Bronze II';
    case 'lol_ldv_bronze-1':
      return 'Bronze I';
    case 'lol_ldv_bronze':
      return 'Bronze';
    case 'lol_ldv_silver-4':
      return 'Silver IV';
    case 'lol_ldv_silver-3':
      return 'Silver III';
    case 'lol_ldv_silver-2':
      return 'Silver II';
    case 'lol_ldv_silver-1':
      return 'Silver I';
    case 'lol_ldv_silver':
      return 'Silver';
    case 'lol_ldv_gold-4':
      return 'Gold IV';
    case 'lol_ldv_gold-3':
      return 'Gold III';
    case 'lol_ldv_gold-2':
      return 'Gold II';
    case 'lol_ldv_gold-1':
      return 'Gold I';
    case 'lol_ldv_gold':
      return 'Gold';
    case 'lol_ldv_platinum-4':
      return 'Platinum IV';
    case 'lol_ldv_platinum-3':
      return 'Platinum III';
    case 'lol_ldv_platinum-2':
      return 'Platinum II';
    case 'lol_ldv_platinum-1':
      return 'Platinum I';
    case 'lol_ldv_platinum':
      return 'Platinum';
    case 'lol_ldv_emerald-4':
      return 'Emerald IV';
    case 'lol_ldv_emerald-3':
      return 'Emerald III';
    case 'lol_ldv_emerald-2':
      return 'Emerald II';
    case 'lol_ldv_emerald-1':
      return 'Emerald I';
    case 'lol_ldv_emerald':
      return 'Emerald';
    case 'lol_ldv_diamond-4':
      return 'Diamond IV';
    case 'lol_ldv_diamond-3':
      return 'Diamond III';
    case 'lol_ldv_diamond-2':
      return 'Diamond II';
    case 'lol_ldv_diamond-1':
      return 'Diamond I';
    case 'lol_ldv_diamond':
      return 'Diamond';
    case 'lol_ldv_master':
      return 'Master';
    case 'lol_ldv_grandmaster':
      return 'Grandmaster';
    case 'lol_ldv_challenger':
      return 'Challenger';
    default:
      return 'Unranked';
  }
}

export function tftLeagueDivisionLabel(leagueDivision: string): string {
  switch (leagueDivision) {
    case 'tft_ldv_unranked':
      return 'Unranked';
    case 'tft_ldv_iron-4':
      return 'Iron IV';
    case 'tft_ldv_iron-3':
      return 'Iron III';
    case 'tft_ldv_iron-2':
      return 'Iron II';
    case 'tft_ldv_iron-1':
      return 'Iron I';
    case 'tft_ldv_iron':
      return 'Iron';
    case 'tft_ldv_bronze-4':
      return 'Bronze IV';
    case 'tft_ldv_bronze-3':
      return 'Bronze III';
    case 'tft_ldv_bronze-2':
      return 'Bronze II';
    case 'tft_ldv_bronze-1':
      return 'Bronze I';
    case 'tft_ldv_bronze':
      return 'Bronze';
    case 'tft_ldv_silver-4':
      return 'Silver IV';
    case 'tft_ldv_silver-3':
      return 'Silver III';
    case 'tft_ldv_silver-2':
      return 'Silver II';
    case 'tft_ldv_silver-1':
      return 'Silver I';
    case 'tft_ldv_silver':
      return 'Silver';
    case 'tft_ldv_gold-4':
      return 'Gold IV';
    case 'tft_ldv_gold-3':
      return 'Gold III';
    case 'tft_ldv_gold-2':
      return 'Gold II';
    case 'tft_ldv_gold-1':
      return 'Gold I';
    case 'tft_ldv_gold':
      return 'Gold';
    case 'tft_ldv_platinum-4':
      return 'Platinum IV';
    case 'tft_ldv_platinum-3':
      return 'Platinum III';
    case 'tft_ldv_platinum-2':
      return 'Platinum II';
    case 'tft_ldv_platinum-1':
      return 'Platinum I';
    case 'tft_ldv_platinum':
      return 'Platinum';
    case 'tft_ldv_emerald-4':
      return 'Emerald IV';
    case 'tft_ldv_emerald-3':
      return 'Emerald III';
    case 'tft_ldv_emerald-2':
      return 'Emerald II';
    case 'tft_ldv_emerald-1':
      return 'Emerald I';
    case 'tft_ldv_emerald':
      return 'Emerald';
    case 'tft_ldv_diamond-4':
      return 'Diamond IV';
    case 'tft_ldv_diamond-3':
      return 'Diamond III';
    case 'tft_ldv_diamond-2':
      return 'Diamond II';
    case 'tft_ldv_diamond-1':
      return 'Diamond I';
    case 'tft_ldv_diamond':
      return 'Diamond';
    case 'tft_ldv_master':
      return 'Master';
    case 'tft_ldv_grandmaster':
      return 'Grandmaster';
    case 'tft_ldv_challenger':
      return 'Challenger';
    default:
      return 'Unranked';
  }
}

export function vrtLeagueDivisionLabel(leagueDivision: string): string {
  switch (leagueDivision) {
    case 'vrt_ldv_unranked':
      return 'Unranked';

    case 'vrt_ldv_iron-1':
      return 'Iron I';

    case 'vrt_ldv_iron-2':
      return 'Iron II';

    case 'vrt_ldv_iron-3':
      return 'Iron III';

    case 'vrt_ldv_iron':
      return 'Iron';

    case 'vrt_ldv_bronze-1':
      return 'Bronze I';

    case 'vrt_ldv_bronze-2':
      return 'Bronze II';

    case 'vrt_ldv_bronze-3':
      return 'Bronze III';

    case 'vrt_ldv_bronze':
      return 'Bronze';

    case 'vrt_ldv_silver-1':
      return 'Silver I';

    case 'vrt_ldv_silver-2':
      return 'Silver II';

    case 'vrt_ldv_silver-3':
      return 'Silver III';

    case 'vrt_ldv_silver':
      return 'Silver';

    case 'vrt_ldv_gold-1':
      return 'Gold I';

    case 'vrt_ldv_gold-2':
      return 'Gold II';

    case 'vrt_ldv_gold-3':
      return 'Gold III';

    case 'vrt_ldv_gold':
      return 'Gold';

    case 'vrt_ldv_platinum-1':
      return 'Platinum I';

    case 'vrt_ldv_platinum-2':
      return 'Platinum II';

    case 'vrt_ldv_platinum-3':
      return 'Platinum III';

    case 'vrt_ldv_platinum':
      return 'Platinum';

    case 'vrt_ldv_diamond-1':
      return 'Diamond I';

    case 'vrt_ldv_diamond-2':
      return 'Diamond II';

    case 'vrt_ldv_diamond-3':
      return 'Diamond III';

    case 'vrt_ldv_diamond':
      return 'Diamond';

    case 'vrt_ldv_ascendant-1':
      return 'Ascendant I';

    case 'vrt_ldv_ascendant-2':
      return 'Ascendant II';

    case 'vrt_ldv_ascendant-3':
      return 'Ascendant III';

    case 'vrt_ldv_ascendant':
      return 'Ascendant';

    case 'vrt_ldv_immortal-1':
      return 'Immortal I';

    case 'vrt_ldv_immortal-2':
      return 'Immortal II';

    case 'vrt_ldv_immortal-3':
      return 'Immortal III';

    case 'vrt_ldv_immortal':
      return 'Immortal';

    case 'vrt_ldv_radiant':
      return 'Radiant';

    default:
      return 'Unranked';
  }
}

export function overwatchLeagueDivisionLabel(leagueDivision: string): string {
  switch (leagueDivision) {
    case 'ow_ldv_bronze-5':
      return 'Bronze';
    case 'ow_ldv_bronze-4':
      return 'Bronze IV';
    case 'ow_ldv_bronze-3':
      return 'Bronze III';
    case 'ow_ldv_bronze-2':
      return 'Bronze II';
    case 'ow_ldv_bronze-1':
      return 'Bronze I';
    case 'ow_ldv_silver-5':
      return 'Silver V';
    case 'ow_ldv_silver-4':
      return 'Silver IV';
    case 'ow_ldv_silver-3':
      return 'Silver III';
    case 'ow_ldv_silver-2':
      return 'Silver II';
    case 'ow_ldv_silver-1':
      return 'Silver I';
    case 'ow_ldv_gold-5':
      return 'Gold V';
    case 'ow_ldv_gold-4':
      return 'Gold IV';
    case 'ow_ldv_gold-3':
      return 'Gold III';
    case 'ow_ldv_gold-2':
      return 'Gold II';
    case 'ow_ldv_gold-1':
      return 'Gold I';
    case 'ow_ldv_platinum-5':
      return 'Platinum V';
    case 'ow_ldv_platinum-4':
      return 'Platinum IV';
    case 'ow_ldv_platinum-3':
      return 'Platinum III';
    case 'ow_ldv_platinum-2':
      return 'Platinum II';
    case 'ow_ldv_platinum-1':
      return 'Platinum I';
    case 'ow_ldv_diamond-5':
      return 'Diamond V';
    case 'ow_ldv_diamond-4':
      return 'Diamond IV';
    case 'ow_ldv_diamond-3':
      return 'Diamond III';
    case 'ow_ldv_diamond-2':
      return 'Diamond II';
    case 'ow_ldv_diamond-1':
      return 'Diamond I';
    case 'ow_ldv_master-5':
      return 'Master V';
    case 'ow_ldv_master-4':
      return 'Master IV';
    case 'ow_ldv_master-3':
      return 'Master III';
    case 'ow_ldv_master-2':
      return 'Master II';
    case 'ow_ldv_master-1':
      return 'Master I';
    case 'ow_ldv_grandmaster-5':
      return 'Grandmaster V';
    case 'ow_ldv_grandmaster-4':
      return 'Grandmaster IV';
    case 'ow_ldv_grandmaster-3':
      return 'Grandmaster III';
    case 'ow_ldv_grandmaster-2':
      return 'Grandmaster II';
    case 'ow_ldv_grandmaster-1':
      return 'Grandmaster I';
    case 'ow_ldv_top500':
      return 'Top 500';
    default:
      return 'Unranked';
  }
}

export function apexLeagueDivisionLabel(leagueDivision: string): string {
  switch (leagueDivision) {
    case 'ap_ldv_unranked':
      return 'Unranked';
    case 'ap_ldv_rookie':
      return 'Rookie';
    case 'ap_ldv_bronze':
      return 'Bronze';
    case 'ap_ldv_silver':
      return 'Silver';
    case 'ap_ldv_gold':
      return 'Gold';
    case 'ap_ldv_platinum':
      return 'Platinum';
    case 'ap_ldv_diamond':
      return 'Diamond';
    case 'ap_ldv_master':
      return 'Master';
    case 'ap_ldv_predator':
      return 'Predator';
    default:
      return '';
  }
}

export function rocketLeagueDivisionLabel(leagueDivision: string): string {
  switch (leagueDivision) {
    case 'rl_ldv_unranked':
      return 'Unranked';
    case 'rl_ldv_bronze-1':
      return 'Bronze I';
    case 'rl_ldv_bronze-2':
      return 'Bronze II';
    case 'rl_ldv_bronze-3':
      return 'Bronze III';
    case 'rl_ldv_bronze':
      return 'Bronze';
    case 'rl_ldv_silver-1':
      return 'Silver I';
    case 'rl_ldv_silver-2':
      return 'Silver II';
    case 'rl_ldv_silver-3':
      return 'Silver III';
    case 'rl_ldv_silver':
      return 'Silver';
    case 'rl_ldv_gold-1':
      return 'Gold I';
    case 'rl_ldv_gold-2':
      return 'Gold II';
    case 'rl_ldv_gold-3':
      return 'Gold III';
    case 'rl_ldv_gold':
      return 'Gold';
    case 'rl_ldv_platinum-1':
      return 'Platinum I';
    case 'rl_ldv_platinum-2':
      return 'Platinum II';
    case 'rl_ldv_platinum-3':
      return 'Platinum III';
    case 'rl_ldv_platinum':
      return 'Platinum';
    case 'rl_ldv_diamond-1':
      return 'Diamond I';
    case 'rl_ldv_diamond-2':
      return 'Diamond II';
    case 'rl_ldv_diamond-3':
      return 'Diamond III';
    case 'rl_ldv_diamond':
      return 'Diamond';
    case 'rl_ldv_champion-1':
      return 'Champion I';
    case 'rl_ldv_champion-2':
      return 'Champion II';
    case 'rl_ldv_champion-3':
      return 'Champion III';
    case 'rl_ldv_champion':
      return 'Champion';
    case 'rl_ldv_grand_champion-1':
      return 'Grand Champion I';
    case 'rl_ldv_grand_champion-2':
      return 'Grand Champion II';
    case 'rl_ldv_grand_champion-3':
      return 'Grand Champion III';
    case 'rl_ldv_grand_champion':
      return 'Grand Champion';
    case 'rl_ldv_supersonic_legend':
      return 'Supersonic Legend';
    default:
      return leagueDivision;
  }
}

export function csLeagueDivisionLabel(division: string): string {
  switch (division) {
    case 'cs_ldv_silver_1':
      return 'Silver I';
    case 'cs_ldv_silver_2':
      return 'Silver II';
    case 'cs_ldv_silver_3':
      return 'Silver III';
    case 'cs_ldv_silver_4':
      return 'Silver IV';
    case 'cs_ldv_silver_elite':
      return 'Silver Elite';
    case 'cs_ldv_silver_elite_master':
      return 'Silver Elite Master';
    case 'cs_ldv_gold_nova_1':
      return 'Gold Nova I';
    case 'cs_ldv_gold_nova_2':
      return 'Gold Nova II';
    case 'cs_ldv_gold_nova_3':
      return 'Gold Nova III';
    case 'cs_ldv_gold_nova_master':
      return 'Gold Nova Master';
    case 'cs_ldv_master_guardian_1':
      return 'Master Guardian I';
    case 'cs_ldv_master_guardian_2':
      return 'Master Guardian II';
    case 'cs_ldv_master_guardian_elite':
      return 'Master Guardian Elite';
    case 'cs_ldv_distinguished_master_guardian':
      return 'Distinguished Master Guardian';
    case 'cs_ldv_legendary_eagle':
      return 'Legendary Eagle';
    case 'cs_ldv_legendary_eagle_master':
      return 'Legendary Eagle Master';
    case 'cs_ldv_supreme_master_first_class':
      return 'Supreme Master First Class';
    case 'cs_ldv_global_elite':
      return 'Global Elite';
    default:
      return division;
  }
}

export function wildriftLeagueDivisionLabel(leagueDivision: string): string {
  switch (leagueDivision) {
    case 'wr_ldv_unranked':
      return 'Unranked';
    case 'wr_ldv_iron':
      return 'Iron';
    case 'wr_ldv_iron-4':
      return 'Iron IV';
    case 'wr_ldv_iron-3':
      return 'Iron III';
    case 'wr_ldv_iron-2':
      return 'Iron II';
    case 'wr_ldv_iron-1':
      return 'Iron I';
    case 'wr_ldv_bronze':
      return 'Bronze';
    case 'wr_ldv_bronze-4':
      return 'Bronze IV';
    case 'wr_ldv_bronze-3':
      return 'Bronze III';
    case 'wr_ldv_bronze-2':
      return 'Bronze II';
    case 'wr_ldv_bronze-1':
      return 'Bronze I';
    case 'wr_ldv_silver':
      return 'Silver';
    case 'wr_ldv_silver-4':
      return 'Silver IV';
    case 'wr_ldv_silver-3':
      return 'Silver III';
    case 'wr_ldv_silver-2':
      return 'Silver II';
    case 'wr_ldv_silver-1':
      return 'Silver I';
    case 'wr_ldv_gold':
      return 'Gold';
    case 'wr_ldv_gold-4':
      return 'Gold IV';
    case 'wr_ldv_gold-3':
      return 'Gold III';
    case 'wr_ldv_gold-2':
      return 'Gold II';
    case 'wr_ldv_gold-1':
      return 'Gold I';
    case 'wr_ldv_platinum':
      return 'Platinum';
    case 'wr_ldv_platinum-4':
      return 'Platinum IV';
    case 'wr_ldv_platinum-3':
      return 'Platinum III';
    case 'wr_ldv_platinum-2':
      return 'Platinum II';
    case 'wr_ldv_platinum-1':
      return 'Platinum I';
    case 'wr_ldv_emerald':
      return 'Emerald';
    case 'wr_ldv_emerald-4':
      return 'Emerald IV';
    case 'wr_ldv_emerald-3':
      return 'Emerald III';
    case 'wr_ldv_emerald-2':
      return 'Emerald II';
    case 'wr_ldv_emerald-1':
      return 'Emerald I';
    case 'wr_ldv_diamond':
      return 'Diamond';
    case 'wr_ldv_diamond-4':
      return 'Diamond IV';
    case 'wr_ldv_diamond-3':
      return 'Diamond III';
    case 'wr_ldv_diamond-2':
      return 'Diamond II';
    case 'wr_ldv_diamond-1':
      return 'Diamond I';
    case 'wr_ldv_master':
      return 'Master';
    case 'wr_ldv_grandmaster':
      return 'Grandmaster';
    case 'wr_ldv_challenger':
      return 'Challenger';
    default:
      return 'Unranked';
  }
}
